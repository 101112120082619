import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_content, Http_send } from "../fable_modules/Fable.SimpleHttp.3.5.0/Http.fs.js";
import { HttpMethod, BodyContent } from "../fable_modules/Fable.SimpleHttp.3.5.0/Types.fs.js";
import { getIdToken } from "../Authentication.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

export function saveUserPreferences(userPreferences) {
    return singleton.Delay(() => {
        let req_2, req_1;
        const payload = Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(userPreferences);
        return singleton.Bind(Http_send(Http_content(new BodyContent(1, [payload]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/UserPreferences/Update`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 204) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

