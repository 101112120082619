import { join, compare } from "../fable_modules/fable-library.4.10.0/String.js";
import { createElement } from "react";
import { compareArrays, comparePrimitives, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { map as map_1, empty, append, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { ofArray as ofArray_1, singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/List.js";
import { FSharpMap__TryFind, ofArray } from "../fable_modules/fable-library.4.10.0/Map.js";
import { sortBy, map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DateTimeOffset_format } from "../Extensions.fs.js";
import { minValue } from "../fable_modules/fable-library.4.10.0/DateOffset.js";
import { compare as compare_1 } from "../fable_modules/fable-library.4.10.0/Date.js";

export function render(shipment) {
    let elems_4;
    const isOcean = compare("Ocean", shipment.ModeOfTransport, 5) === 0;
    const hasTransitEvents = isOcean ? (shipment.ContainerEvents.length > 0) : (shipment.CarrierEvents.length > 0);
    return createElement("div", createObj(singleton_1((elems_4 = toList(delay(() => {
        let elems_3, elems_2;
        return !hasTransitEvents ? singleton(createElement("div", {
            className: join(" ", ["mt-4", "has-text-centered"]),
            children: "No transit events were found for this shipment",
        })) : singleton(createElement("div", createObj(ofArray_1([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
            maxHeight: 450 + "px",
            overflowY: "auto",
        }], (elems_3 = [createElement("table", createObj(ofArray_1([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_2 = toList(delay(() => {
            let children_2, children;
            return append(singleton((children_2 = singleton_1((children = toList(delay(() => append(isOcean ? singleton(createElement("th", {
                className: join(" ", ["is-size-7", "p-2"]),
                scope: "col",
                children: "Container Number",
            })) : empty(), delay(() => append(singleton(createElement("th", {
                className: join(" ", ["is-size-7", "p-2"]),
                scope: "col",
                children: "Code",
            })), delay(() => append(singleton(createElement("th", {
                className: join(" ", ["is-size-7", "p-2"]),
                scope: "col",
                children: "Event",
            })), delay(() => append(singleton(createElement("th", {
                className: join(" ", ["is-size-7", "p-2"]),
                scope: "col",
                children: "Date",
            })), delay(() => append(singleton(createElement("th", {
                className: join(" ", ["is-size-7", "p-2"]),
                scope: "col",
                children: "Provided By",
            })), delay(() => singleton(createElement("th", {
                className: join(" ", ["is-size-7", "p-2"]),
                scope: "col",
                children: "Location",
            })))))))))))))), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => {
                let elems, elems_1;
                return isOcean ? singleton(createElement("tbody", createObj(singleton_1((elems = toList(delay(() => {
                    const containersMap = ofArray(map((container) => [container.Id, container.Number], shipment.Containers), {
                        Compare: comparePrimitives,
                    });
                    return map_1((containerEvent) => {
                        const children_4 = ofArray_1([createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: defaultArg(FSharpMap__TryFind(containersMap, containerEvent.ContainerId), ""),
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: containerEvent.EventCode,
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: containerEvent.EventName,
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: DateTimeOffset_format(containerEvent.EventDateTime),
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: containerEvent.PortName,
                        }), createElement("td", {
                            className: join(" ", ["is-size-7", "p-2"]),
                            children: containerEvent.Location,
                        })]);
                        return createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                        });
                    }, sortBy((containerEvent_1) => {
                        const matchValue = FSharpMap__TryFind(containersMap, containerEvent_1.ContainerId);
                        if (matchValue != null) {
                            return [matchValue, containerEvent_1.EventDateTime];
                        }
                        else {
                            return ["", minValue()];
                        }
                    }, shipment.ContainerEvents, {
                        Compare: compareArrays,
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))) : singleton(createElement("tbody", createObj(singleton_1((elems_1 = toList(delay(() => map_1((carrierEvent) => {
                    const children_6 = ofArray_1([createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent.EventCode,
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent.EventName,
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: DateTimeOffset_format(carrierEvent.EventDate),
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent.PortName,
                    }), createElement("td", {
                        className: join(" ", ["is-size-7", "p-2"]),
                        children: carrierEvent.Location,
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    });
                }, sortBy((x_2) => x_2.EventDate, shipment.CarrierEvents, {
                    Compare: compare_1,
                })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))));
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]))));
}

