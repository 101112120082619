import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, append, collect, singleton, delay as delay_1, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { value as value_78 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DateTimeOffset_format } from "../Extensions.fs.js";

export function render(delays) {
    let elems_6;
    return createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
        maxHeight: 450 + "px",
        overflowY: "auto",
    }], (elems_6 = toList(delay_1(() => {
        let elems_5, elems, children, elems_4;
        return (delays.length === 0) ? singleton(createElement("div", {
            className: "has-text-centered",
            children: "No delays were found for this shipment",
        })) : singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_5 = [createElement("thead", createObj(singleton_1((elems = [(children = ofArray([createElement("th", {
            className: "p-2",
            scope: "col",
            children: "",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Reason",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Date",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Remarks",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Controllable",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("tbody", createObj(singleton_1((elems_4 = toList(delay_1(() => collect((delay) => {
            let elems_3, elems_1, elems_2;
            const isControllable = (delay.IsControllable != null) ? (value_78(delay.IsControllable) ? "Yes" : "No") : "";
            return singleton(createElement("tr", createObj(singleton_1((elems_3 = [createElement("td", createObj(ofArray([["className", "p-2"], (elems_1 = [createElement("div", createObj(toList(delay_1(() => (delay.IsPotential ? append(singleton(["className", "is-warning tag"]), delay_1(() => singleton(["children", "Potential Delay"]))) : append(singleton(["className", "is-danger tag"]), delay_1(() => singleton(["children", "Delayed"]))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("td", {
                className: "p-2",
                children: delay.Reason,
            }), createElement("td", {
                className: "p-2",
                children: DateTimeOffset_format(delay.Date),
            }), createElement("td", {
                className: "p-2",
                children: delay.Remarks,
            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_2 = [createElement("div", createObj(toList(delay_1(() => append(!isNullOrWhiteSpace(isControllable) ? singleton(["className", "tag"]) : empty(), delay_1(() => singleton(["children", isControllable])))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])))));
        }, delays))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

