import { Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";

export class IconType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ModeOfTransport", "ShipmentLocation"];
    }
}

export function IconType_$reflection() {
    return union_type("CView.UI.ModeOfTransportIcon.IconType", [], IconType, () => [[], []]);
}

export function ModeOfTransportIcon_render(iconType, modeOfTransportIcon) {
    const rotateClass = (modeOfTransportIcon === "flight") ? "rotate-90" : "";
    return createElement("i", {
        className: join(" ", (iconType.tag === 1) ? ofArray(["material-icons", rotateClass, "step-icon"]) : ofArray(["material-icons", rotateClass, "mot-icon", "has-text-link", "mr-3"])),
        children: modeOfTransportIcon,
    });
}

