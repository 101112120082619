import { createElement } from "react";
import { comparePrimitives, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { map, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatDecimalWithUnits } from "../Domain/Formatter.fs.js";
import { DateTimeOffset_formatNullable } from "../Extensions.fs.js";
import { sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";

export function render(purchaseOrderLineItems) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", "mx-4"], (elems_4 = toList(delay(() => {
        let elems_3, elems_2, children_2, children, elems_1;
        return (purchaseOrderLineItems.length === 0) ? singleton(createElement("div", {
            className: "has-text-centered",
            children: "No purchase order line items were found for this purchase order",
        })) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
            maxHeight: 450 + "px",
            overflowY: "auto",
        }], (elems_3 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_2 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
            className: "p-2",
            children: "Line Item",
        }), createElement("th", {
            className: "p-2",
            children: "Quantity Ordered",
        }), createElement("th", {
            className: "p-2",
            children: "Part Number",
        }), createElement("th", {
            className: "p-2",
            children: "Description",
        }), createElement("th", {
            className: "p-2",
            children: "HTS | Schedule B",
        }), createElement("th", {
            className: "p-2",
            children: "MOT",
        }), createElement("th", {
            className: "p-2",
            children: "Entry Date",
        }), createElement("th", {
            className: "p-2",
            children: "Promised Date",
        }), createElement("th", {
            className: "p-2",
            children: "Status",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), createElement("tbody", createObj(ofArray([["className", ""], (elems_1 = toList(delay(() => map((purchaseOrderLineItem) => {
            let elems;
            return createElement("tr", createObj(ofArray([["className", join(" ", ["is-clickable", ""])], (elems = [createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.LineItemNumber,
            }), createElement("td", {
                className: "p-2",
                children: formatDecimalWithUnits(purchaseOrderLineItem.QuantityOrderedUnit, purchaseOrderLineItem.QuantityOrdered),
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.PartNumber,
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.Description,
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.HtsCode,
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.ModeOfTransportName,
            }), createElement("td", {
                className: "p-2",
                children: DateTimeOffset_formatNullable(purchaseOrderLineItem.EntryDate),
            }), createElement("td", {
                className: "p-2",
                children: DateTimeOffset_formatNullable(purchaseOrderLineItem.PromiseDate),
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.StatusName,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }, sortBy((purchaseOrderLineItem_1) => purchaseOrderLineItem_1.LineItemNumber, purchaseOrderLineItems, {
            Compare: comparePrimitives,
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

