import { tryParse } from "../CView.UI/fable_modules/fable-library.4.10.0/Boolean.js";
import { FSharpRef } from "../CView.UI/fable_modules/fable-library.4.10.0/Types.js";
import { tryParse as tryParse_1, minValue } from "../CView.UI/fable_modules/fable-library.4.10.0/Date.js";
import { tryParse as tryParse_2, minValue as minValue_1 } from "../CView.UI/fable_modules/fable-library.4.10.0/DateOffset.js";
import { tryParse as tryParse_3 } from "../CView.UI/fable_modules/fable-library.4.10.0/Decimal.js";
import Decimal from "../CView.UI/fable_modules/fable-library.4.10.0/Decimal.js";
import { tryParse as tryParse_4 } from "../CView.UI/fable_modules/fable-library.4.10.0/Guid.js";
import { defaultOf } from "../CView.UI/fable_modules/fable-library.4.10.0/Util.js";
import { parse, tryParse as tryParse_5 } from "../CView.UI/fable_modules/fable-library.4.10.0/Int32.js";
import { tryParse as tryParse_6 } from "../CView.UI/fable_modules/fable-library.4.10.0/Long.js";
import { compare, substring, split, join, isNullOrWhiteSpace } from "../CView.UI/fable_modules/fable-library.4.10.0/String.js";
import { map } from "../CView.UI/fable_modules/fable-library.4.10.0/List.js";
import { map as map_1 } from "../CView.UI/fable_modules/fable-library.4.10.0/Array.js";
import { get_UTF8 } from "../CView.UI/fable_modules/fable-library.4.10.0/Encoding.js";

export function Boolean_fromString(value) {
    let matchValue;
    let outArg = false;
    matchValue = [tryParse(value, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function DateTime_fromString(value) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse_1(value, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function DateTimeOffset_fromString(value) {
    let matchValue;
    let outArg = minValue_1();
    matchValue = [tryParse_2(value, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Decimal_fromString(value) {
    let matchValue;
    let outArg = new Decimal("0");
    matchValue = [tryParse_3(value, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Guid_fromString(value) {
    let matchValue;
    let outArg = "00000000-0000-0000-0000-000000000000";
    matchValue = [tryParse_4(value, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Guid_toOptionIfEmpty(value) {
    if (value === "00000000-0000-0000-0000-000000000000") {
        return void 0;
    }
    else {
        return value;
    }
}

export function Guid_toNullableIfEmpty(value) {
    if (value === "00000000-0000-0000-0000-000000000000") {
        return defaultOf();
    }
    else {
        return value;
    }
}

export function Int32_fromString(value) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse_5(value, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Int64_fromString(value) {
    let matchValue;
    let outArg = 0n;
    matchValue = [tryParse_6(value, 511, false, 64, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function String_defaultValue() {
    return defaultOf();
}

export const String_isNotNullOrWhiteSpace = (arg) => !isNullOrWhiteSpace(arg);

export function String_toNullIfEmpty(value) {
    if (isNullOrWhiteSpace(value)) {
        return defaultOf();
    }
    else {
        return value;
    }
}

export function String_toUpper(value) {
    return value.toLocaleUpperCase();
}

export function String_toLower(value) {
    return value.toLocaleLowerCase();
}

export function String_trim(value) {
    return value.trim();
}

export function String_toQueryString(query) {
    return join("&", map((tupledArg) => (`${tupledArg[0]}=${tupledArg[1]}`), query));
}

export function String_toByteArray(value) {
    return map_1((delegateArg) => parse(delegateArg, 511, true, 8), String_isNotNullOrWhiteSpace(value) ? split(value, [","], void 0, 0) : (new Array(0)));
}

export function String_toOptionIfEmpty(value) {
    if (isNullOrWhiteSpace(value)) {
        return void 0;
    }
    else {
        return value.trim();
    }
}

export function String_capitalize(value) {
    const character = value[0];
    return character.toLocaleUpperCase() + substring(value, 1);
}

export function String_equalsOrdinalIgnoreCase(x, y) {
    return compare(x, y, 5) === 0;
}

export function String_decode(value) {
    return get_UTF8().getString(value);
}

