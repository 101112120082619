/**
 * Format a date time as month day, year
 * @param {Date} dateTime
 */
export function formatDateTime(dateTime) {
    if (!dateTime) {
        return ""
    }

    const month = dateTime.toLocaleString('default', { month: 'short' });
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();

    return month + " " + day + ", " + year;
}

/**
 * Format a date time offset as month day, year hh:mm:ss AM|PM
 * @param {Date} dateTime
 */
export function formatDateTimeOffset(dateTimeOffset) {
    if (!dateTimeOffset) {
        return ""
    }

    const month = dateTimeOffset.toLocaleString('default', { month: 'short' });
    const day = dateTimeOffset.getDate();
    const year = dateTimeOffset.getFullYear();

    let options = { hour: "2-digit", minute: "2-digit" };// take only the hour and minutes part
    const time = dateTimeOffset.toLocaleTimeString([],options);
    return month + " " + day + ", " + year + " " + time;
}