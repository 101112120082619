import { searchGlobalAccounts, search } from "./AccountApi.fs.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { choose, map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { DataSource } from "../Models.fs.js";
import { search as search_1 } from "./BookingAccountApi.fs.js";
import { search as search_2 } from "./ShipmentAccountApi.fs.js";
import { LocationSearchQuery, EntityAccountSearchQuery, AccountSearchQuery } from "../../CView.DTOs/DTOs.fs.js";
import { toString } from "../fable_modules/fable-library.4.10.0/Types.js";
import { searchShipmentLocations, searchBookingLocations } from "./LocationApi.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { String_toOptionIfEmpty, String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { getDocumentTypes as getDocumentTypes_1 } from "./DocumentApi.fs.js";
import { searchWarehouses } from "./InventoryApi.fs.js";
import { WarehouseSearchQuery } from "../../CView.DTOs/Inventory.fs.js";

function getAccounts(query) {
    const input = search(query);
    return singleton.Bind(input, (x$0027) => {
        let items;
        let value;
        const input_2 = x$0027;
        value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(items = input_2.fields[0], new DataSource(items.length > 0, map((item) => ({
            Id: item.Id,
            DisplayValue: item.Name,
            Value: item,
        }), items)))])));
        return singleton.Return(value);
    });
}

function getSearchEntityAccounts(searchType, query) {
    const input = (searchType.tag === 1) ? search_1(query) : search_2(query);
    return singleton.Bind(input, (x$0027) => {
        let value;
        const input_2 = x$0027;
        value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((item) => ({
            Id: item.Name,
            DisplayValue: item.Name,
            Value: item,
        }), input_2.fields[0])])));
        return singleton.Return(value);
    });
}

export function getCustomers(searchCriteria) {
    return getAccounts(new AccountSearchQuery(searchCriteria, new Array(0)));
}

export function getConsignees(searchType, accountIds, searchCriteria) {
    return getSearchEntityAccounts(searchType, new EntityAccountSearchQuery(searchCriteria, "Consignee", accountIds));
}

export function getShippers(searchType, accountIds, searchCriteria) {
    return getSearchEntityAccounts(searchType, new EntityAccountSearchQuery(searchCriteria, "Shipper", accountIds));
}

export function getLocations(entity, locationType, accountIds, searchCriteria) {
    const locationSearchQuery = new LocationSearchQuery(searchCriteria, accountIds, toString(locationType));
    const input = (entity.tag === 1) ? searchBookingLocations(locationSearchQuery) : searchShipmentLocations(locationSearchQuery);
    return singleton.Bind(input, (x$0027) => {
        let value;
        const input_2 = x$0027;
        value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((item) => (new (class {
            get Id() {
                return item.CityName;
            }
            get DisplayValue() {
                const matchValue = isNullOrWhiteSpace(item.CityName);
                const matchValue_1 = isNullOrWhiteSpace(item.StateOrProvinceCode);
                const matchValue_2 = isNullOrWhiteSpace(item.CountryCode);
                let matchResult;
                if (matchValue) {
                    matchResult = 2;
                }
                else if (matchValue_1) {
                    if (matchValue_2) {
                        matchResult = 2;
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else if (matchValue_2) {
                    matchResult = 2;
                }
                else {
                    matchResult = 0;
                }
                switch (matchResult) {
                    case 0:
                        return `${item.CityName} ${item.StateOrProvinceCode}, ${item.CountryCode}`;
                    case 1:
                        return `${item.CityName}, ${item.CountryCode}`;
                    default:
                        return "";
                }
            }
            get Value() {
                const matchValue_4 = isNullOrWhiteSpace(item.CityCode);
                const matchValue_5 = isNullOrWhiteSpace(item.CountryCode);
                let matchResult_1;
                if (matchValue_4) {
                    matchResult_1 = 1;
                }
                else if (matchValue_5) {
                    matchResult_1 = 1;
                }
                else {
                    matchResult_1 = 0;
                }
                switch (matchResult_1) {
                    case 0:
                        return `${item.CityCode}${item.CountryCode}`;
                    default:
                        return String_defaultValue();
                }
            }
        }
        )()), input_2.fields[0].Data)])));
        return singleton.Return(value);
    });
}

export function getDocumentTypes(searchCriteria) {
    const input = getDocumentTypes_1();
    return singleton.Bind(input, (x$0027) => {
        let value;
        const input_2 = x$0027;
        value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [choose((item) => {
            if ((item.Description.toLocaleLowerCase().indexOf(searchCriteria.toLocaleLowerCase()) >= 0) ? true : (item.Code.toLocaleLowerCase().indexOf(searchCriteria.toLocaleLowerCase()) >= 0)) {
                return {
                    Id: item.Code,
                    DisplayValue: item.Description,
                    Value: item,
                };
            }
            else {
                return void 0;
            }
        }, input_2.fields[0])])));
        return singleton.Return(value);
    });
}

export function getGlobalAccounts(searchCriteria) {
    const input = searchGlobalAccounts(String_toOptionIfEmpty(searchCriteria), true, true, 1, "", "");
    return singleton.Bind(input, (x$0027) => {
        let value;
        const input_2 = x$0027;
        value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((item) => ({
            Id: item.Id,
            DisplayValue: item.Name,
            Value: item,
        }), input_2.fields[0].Data)])));
        return singleton.Return(value);
    });
}

export function getWarehouses(accounts, searchCriteria) {
    const input = searchWarehouses(new WarehouseSearchQuery(searchCriteria, map((x) => x.Id, accounts)));
    return singleton.Bind(input, (x$0027) => {
        let value;
        const input_2 = x$0027;
        value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((item) => ({
            Id: item.WarehouseCode,
            DisplayValue: item.WarehouseName,
            Value: item,
        }), input_2.fields[0].Data)])));
        return singleton.Return(value);
    });
}

