import { Record } from "./fable_modules/fable-library.4.10.0/Types.js";
import { string_type, record_type, array_type, class_type, bool_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";

export class DataSource extends Record {
    constructor(IsPaginated, Data) {
        super();
        this.IsPaginated = IsPaginated;
        this.Data = Data;
    }
}

export function DataSource_$reflection() {
    return record_type("CView.UI.Models.DataSource", [], DataSource, () => [["IsPaginated", bool_type], ["Data", array_type(class_type("CView.UI.Models.IDataSourceItem"))]]);
}

export class SelectableItem extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function SelectableItem_$reflection() {
    return record_type("CView.UI.Models.SelectableItem", [], SelectableItem, () => [["Id", string_type], ["Name", string_type]]);
}

