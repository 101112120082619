import { toString as toString_1, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { DocumentSearchRequest, PagedDataResponse$1_$reflection, DocumentSearchRequest_$reflection, DocumentListItem_$reflection, User_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, class_type, bool_type, lambda_type, record_type, list_type, unit_type, union_type, string_type, option_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTime_format, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { safeHash, defaultOf, createObj, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { contains, ofArray, filter, cons, singleton, empty } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Typeahead_render, Typeahead_setInputValue, Typeahead_init } from "../Components/Typeahead.fs.js";
import { getDocumentTypes } from "../Api/DataSourceApi.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportDocumentsAsZip, export$, search } from "../Api/DocumentApi.fs.js";
import { map as map_1 } from "../fable_modules/fable-library.4.10.0/Array.js";
import { documentExport } from "../Api/ShipmentApi.fs.js";
import { defaultDocumentSearchRequest } from "../Domain/Document.fs.js";
import { createElement } from "react";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { maxValue, minValue, toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { formatInt, toDateTimeOffsetInput } from "../Domain/Formatter.fs.js";
import { value as value_250, toNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DateTimeOffset_fromString } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { map as map_2, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(Accounts, User, SelectedDocument, DocumentSearchQuery, PreviousDocumentSearchQuery, DocumentsDeferred, ExportDocumentMetadataListDeferred, DownloadDocumentDeferred, DocumentsToZip, ExportDocumentsAsZipDeferred) {
        super();
        this.Accounts = Accounts;
        this.User = User;
        this.SelectedDocument = SelectedDocument;
        this.DocumentSearchQuery = DocumentSearchQuery;
        this.PreviousDocumentSearchQuery = PreviousDocumentSearchQuery;
        this.DocumentsDeferred = DocumentsDeferred;
        this.ExportDocumentMetadataListDeferred = ExportDocumentMetadataListDeferred;
        this.DownloadDocumentDeferred = DownloadDocumentDeferred;
        this.DocumentsToZip = DocumentsToZip;
        this.ExportDocumentsAsZipDeferred = ExportDocumentsAsZipDeferred;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Documents.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["SelectedDocument", option_type(DocumentListItem_$reflection())], ["DocumentSearchQuery", DocumentSearchRequest_$reflection()], ["PreviousDocumentSearchQuery", DocumentSearchRequest_$reflection()], ["DocumentsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(DocumentListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(DocumentListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["ExportDocumentMetadataListDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["DownloadDocumentDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["DocumentsToZip", list_type(DocumentListItem_$reflection())], ["ExportDocumentsAsZipDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DocumentQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Documents.Intent", [], Intent, () => [[["Item", DocumentSearchRequest_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateDocumentSearchQuery", "SearchDocumentsAsync", "ExportDocumentMetadataList", "SetSelectedDocumentType", "SetSelectedDocument", "DownloadDocument", "AddDocumentToZipList", "RemoveDocumentFromZipList", "ExportDocumentsAsZip", "ClearSearch", "SetPageNumber"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Documents.Msg", [], Msg, () => [[["Item", lambda_type(DocumentSearchRequest_$reflection(), DocumentSearchRequest_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(DocumentListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(DocumentListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", DocumentListItem_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", DocumentListItem_$reflection()]], [["Item", DocumentListItem_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [], [["Item", int32_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.DocumentsDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.DocumentsDeferred);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.DocumentsDeferred)) {
        return true;
    }
    else {
        return equals_1(state.DocumentsDeferred, new Deferred$1(1, []));
    }
}

export function init(accounts, user, documentSearchRequest) {
    return [new State(accounts, user, void 0, documentSearchRequest, documentSearchRequest, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), empty(), new Deferred$1(0, [])), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    })))];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, msg.fields[1] ? state.PreviousDocumentSearchQuery : state.DocumentSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Documents", Alert_text(msg.fields[0].fields[0].fields[0]))) : Typeahead_init("document", getDocumentTypes, (Item_1) => (new Msg(3, [Item_1])), 500, 1), new Intent(1, [])];
            }
            else if (equals_1(state.DocumentsDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, new Deferred$1(1, []), state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousDocumentSearchQuery, new DocumentSearchRequest(bind$0040.Page, bind$0040.PageSize, bind$0040.OrderBy, bind$0040.OrderDirection, bind$0040.ShipmentTrackingNumber, bind$0040.TypeCode, bind$0040.TypeName, bind$0040.FromDate, bind$0040.ToDate, map_1((x) => x.Id, state.Accounts)))) : ((bind$0040_1 = state.DocumentSearchQuery, new DocumentSearchRequest(1, bind$0040_1.PageSize, bind$0040_1.OrderBy, bind$0040_1.OrderDirection, bind$0040_1.ShipmentTrackingNumber, bind$0040_1.TypeCode, bind$0040_1.TypeName, bind$0040_1.FromDate, bind$0040_1.ToDate, map_1((x_1) => x_1.Id, state.Accounts)))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Documents", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportDocumentMetadataListDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, new Deferred$1(1, []), state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_2 = state.PreviousDocumentSearchQuery, new DocumentSearchRequest(bind$0040_2.Page, bind$0040_2.PageSize, bind$0040_2.OrderBy, bind$0040_2.OrderDirection, bind$0040_2.ShipmentTrackingNumber, bind$0040_2.TypeCode, bind$0040_2.TypeName, bind$0040_2.FromDate, bind$0040_2.ToDate, map_1((x_2) => x_2.Id, state.Accounts))), (arg_2) => (new Msg(2, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 4:
            return [new State(state.Accounts, state.User, msg.fields[0], state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), singleton((dispatch) => {
                dispatch(new Msg(5, [new AsyncMsg$1(0, [])]));
            }), new Intent(1, [])];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Download Document", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.DownloadDocumentDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                const matchValue = state.SelectedDocument;
                if (matchValue != null) {
                    const selectedDocument = matchValue;
                    return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, new Deferred$1(1, []), state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), Cmd_OfAsyncWith_perform((computation_2) => {
                        startImmediate(computation_2);
                    }, () => documentExport(selectedDocument.Id, selectedDocument.Name), void 0, (arg_4) => (new Msg(5, [new AsyncMsg$1(1, [arg_4])]))), new Intent(1, [])];
                }
                else {
                    return [state, Cmd_none(), new Intent(1, [])];
                }
            }
        case 6:
            return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, cons(msg.fields[0], state.DocumentsToZip), state.ExportDocumentsAsZipDeferred), Cmd_none(), new Intent(1, [])];
        case 7:
            return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, filter((d) => (d.Id !== msg.fields[0].Id), state.DocumentsToZip), state.ExportDocumentsAsZipDeferred), Cmd_none(), new Intent(1, [])];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Documents", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportDocumentsAsZipDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.DocumentsToZip, empty())) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, empty(), new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_3) => {
                    startImmediate(computation_3);
                }, exportDocumentsAsZip, state.DocumentsToZip, (arg_6) => (new Msg(8, [new AsyncMsg$1(1, [arg_6])]))), new Intent(1, [])];
            }
        case 9: {
            Typeahead_setInputValue("document", "");
            const newState_1 = new State(state.Accounts, state.User, state.SelectedDocument, defaultDocumentSearchRequest, state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred);
            return [newState_1, singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.DocumentSearchQuery])];
        }
        case 3:
            return [new State(state.Accounts, state.User, state.SelectedDocument, (bind$0040_3 = state.DocumentSearchQuery, new DocumentSearchRequest(bind$0040_3.Page, bind$0040_3.PageSize, bind$0040_3.OrderBy, bind$0040_3.OrderDirection, bind$0040_3.ShipmentTrackingNumber, msg.fields[0].Id, msg.fields[0].DisplayValue, bind$0040_3.FromDate, bind$0040_3.ToDate, bind$0040_3.AccountIds)), state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), Cmd_none(), new Intent(1, [])];
        case 10:
            return [new State(state.Accounts, state.User, state.SelectedDocument, state.DocumentSearchQuery, (bind$0040_4 = state.PreviousDocumentSearchQuery, new DocumentSearchRequest(msg.fields[0], bind$0040_4.PageSize, bind$0040_4.OrderBy, bind$0040_4.OrderDirection, bind$0040_4.ShipmentTrackingNumber, bind$0040_4.TypeCode, bind$0040_4.TypeName, bind$0040_4.FromDate, bind$0040_4.ToDate, bind$0040_4.AccountIds)), state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), singleton((dispatch_2) => {
                dispatch_2(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        default:
            return [new State(state.Accounts, state.User, state.SelectedDocument, msg.fields[0](state.DocumentSearchQuery), state.PreviousDocumentSearchQuery, state.DocumentsDeferred, state.ExportDocumentMetadataListDeferred, state.DownloadDocumentDeferred, state.DocumentsToZip, state.ExportDocumentsAsZipDeferred), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_6, elems, elems_2, elems_1, value_32, elems_3, elems_4, value_60, elems_5, value_81, elems_14, elems_13, elems_12, elems_7, elems_8, matchValue_3, elems_11, elems_24, elems_23, elems_22, elems_15, children, elems_21, elems_28, elems_27;
    const divider = createElement("hr", {
        className: join(" ", ["my-4"]),
    });
    let searchControls;
    const labelCss = ofArray(["is-block", "mb-1", "has-text-weight-semibold"]);
    const inputCss = ofArray(["input", "is-small", "is-fullwidth"]);
    searchControls = createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", join(" ", ["box"])], ["style", {
        minWidth: 300,
    }], (elems_6 = [createElement("div", createObj(ofArray([["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            keyPress.preventDefault();
        }
    }], ["className", join(" ", ["block"])], (elems = [createElement("label", {
        className: join(" ", labelCss),
        for: "DocumentType",
        children: "Document Type",
    }), Typeahead_render("document", "Document Type")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems_1 = [createElement("label", {
        className: join(" ", ["has-text-weight-semibold"]),
        for: "ShipmentTrackingNumber",
        children: "Shipment Tracking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "ShipmentTrackingNumber"], ["name", "ShipmentTrackingNumber"], ["type", "text"], ["maxLength", 256], ["placeholder", "Shipment Tracking Number..."], (value_32 = state.DocumentSearchQuery.ShipmentTrackingNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_32)) {
            e_1.value = value_32;
        }
    }]), ["onChange", (ev) => {
        const value_34 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new DocumentSearchRequest(query.Page, query.PageSize, query.OrderBy, query.OrderDirection, value_34, query.TypeCode, query.TypeName, query.FromDate, query.ToDate, query.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_3 = [createElement("label", {
        className: join(" ", labelCss),
        for: "ShipmentStartDate",
        children: "Shipment Created Date",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_4 = [createElement("label", {
        className: join(" ", labelCss),
        for: "FromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "FromDate"], ["name", "FromDate"], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_60 = toDateTimeOffsetInput(state.DocumentSearchQuery.FromDate), ["ref", (e_2) => {
        if (!(e_2 == null) && !equals_1(e_2.value, value_60)) {
            e_2.value = value_60;
        }
    }]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new DocumentSearchRequest(query_1.Page, query_1.PageSize, query_1.OrderBy, query_1.OrderDirection, query_1.ShipmentTrackingNumber, query_1.TypeCode, query_1.TypeName, isNullOrWhiteSpace(element.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element.value)), query_1.ToDate, query_1.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_5 = [createElement("label", {
        className: join(" ", labelCss),
        for: "ToDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "ToDate"], ["name", "ToDate"], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_81 = toDateTimeOffsetInput(state.DocumentSearchQuery.ToDate), ["ref", (e_4) => {
        if (!(e_4 == null) && !equals_1(e_4.value, value_81)) {
            e_4.value = value_81;
        }
    }]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_2) => (new DocumentSearchRequest(query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection, query_2.ShipmentTrackingNumber, query_2.TypeCode, query_2.TypeName, query_2.FromDate, isNullOrWhiteSpace(element_1.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_1.value)), query_2.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), divider, createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.DocumentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.DocumentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))))))))),
        type: "button",
        onClick: (_arg) => {
            dispatch(new Msg(9, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
    const header = createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_12 = [createElement("div", createObj(ofArray([["className", "column"], (elems_7 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Documents",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_8 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold"]),
        children: (matchValue_3 = state.DocumentsDeferred, (matchValue_3.tag === 1) ? "Searching..." : ((matchValue_3.tag === 2) ? ((matchValue_3.fields[0].tag === 0) ? ((compare(matchValue_3.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue_3.fields[0].fields[0].TotalCount))} documents found | showing page ${formatInt(matchValue_3.fields[0].fields[0].Page)} of ${formatInt(matchValue_3.fields[0].fields[0].NumberOfPages)}`) : "0 documents found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column is-narrow"], (elems_11 = toList(delay(() => {
        let elems_9;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(10, [state.PreviousDocumentSearchQuery.Page - 1]));
            }
        }], (elems_9 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: "is-sr-only",
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => {
            let elems_10;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(10, [state.PreviousDocumentSearchQuery.Page + 1]));
                }
            }], (elems_10 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: join(" ", ["is-sr-only"]),
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))), delay(() => append(state.User.CanExportDocuments ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small", "mr-2", equals_1(state.DocumentsToZip, empty()) ? "is-hidden" : ""]),
                hidden: true,
                type: "button",
                children: (state.ExportDocumentsAsZipDeferred.tag === 1) ? "Creating zip..." : "Download zip archive",
                disabled: equals_1(state.ExportDocumentsAsZipDeferred, new Deferred$1(1, [])),
                onClick: (_arg_3) => {
                    dispatch(new Msg(8, [new AsyncMsg$1(0, [])]));
                },
            })) : empty_1(), delay(() => (state.User.CanExportDocuments ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small"]),
                type: "button",
                children: (state.ExportDocumentMetadataListDeferred.tag === 1) ? "Exporting..." : "Export",
                disabled: equals_1(state.ExportDocumentMetadataListDeferred, new Deferred$1(1, [])) ? true : isExportDisabled(state),
                onClick: (_arg_4) => {
                    dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                },
            })) : empty_1())))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])));
    let searchResult;
    const matchValue_6 = state.DocumentsDeferred;
    searchResult = ((matchValue_6.tag === 1) ? render_1() : ((matchValue_6.tag === 2) ? ((matchValue_6.fields[0].tag === 0) ? createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_24 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_23 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_22 = [createElement("thead", createObj(singleton((elems_15 = [(children = ofArray([createElement("th", {
        className: join(" ", ["p-2"]),
        scope: "col",
        children: "",
    }), createElement("th", {
        className: join(" ", ["p-2"]),
        scope: "col",
        children: "Document Name",
    }), createElement("th", {
        className: join(" ", ["p-2"]),
        scope: "col",
        children: "Document Type",
    }), createElement("th", {
        className: join(" ", ["p-2"]),
        scope: "col",
        children: "Shipment",
    }), createElement("th", {
        className: join(" ", ["p-2"]),
        scope: "col",
        children: "Shipment Created Date",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])))), createElement("tbody", createObj(singleton((elems_21 = toList(delay(() => map_2((document$) => {
        let elems_16, elems_17, elems_20, elems_19, elems_18;
        const children_2 = ofArray([createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_16 = [createElement("input", {
            id: document$.Id,
            name: document$.Name,
            type: "checkbox",
            checked: contains(document$, state.DocumentsToZip, {
                Equals: equals_1,
                GetHashCode: safeHash,
            }),
            onChange: (ev_1) => {
                const value_206 = ev_1.target.checked;
                if (value_206) {
                    dispatch(new Msg(6, [document$]));
                }
                else {
                    dispatch(new Msg(7, [document$]));
                }
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_17 = [createElement("a", {
            className: join(" ", ["is-link", "is-underlined"]),
            style: {
                overflowWrap: "anywhere",
            },
            onClick: (_arg_5) => {
                dispatch(new Msg(4, [document$]));
            },
            children: document$.Name,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("td", {
            className: join(" ", ["p-2"]),
            children: document$.TypeName,
        }), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_20 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-center", "is-align-items-center"])], (elems_19 = [ModeOfTransportIcon_render(new IconType(0, []), document$.ModeOfTransportIcon), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_18 = [createElement("a", {
            className: join(" ", ["is-link"]),
            href: RouterModule_encodeParts(ofArray(["/shipment-details", toString_1(document$.ShipmentId)]), 1),
            children: document$.ShipmentNumber,
        }), createElement("div", {
            children: [document$.ServiceType],
        }), createElement("div", {
            children: [document$.ServiceLevel],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("td", {
            className: join(" ", ["p-2"]),
            children: (document$.ShipmentStartDate != null) ? DateTime_format(value_250(document$.ShipmentStartDate)) : "",
        })]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, matchValue_6.fields[0].fields[0].Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))) : defaultOf_1()) : defaultOf_1()));
    if (state.Accounts.length === 0) {
        const children_4 = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_28 = [header, createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_27 = [createElement("div", {
            className: join(" ", ["column", "is-narrow"]),
            children: Interop_reactApi.Children.toArray([searchControls]),
        }), createElement("div", {
            className: join(" ", ["column"]),
            children: Interop_reactApi.Children.toArray([searchResult]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))]))));
    }
}

