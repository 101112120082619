import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";

export function render(shipmentId, containers) {
    let elems_3;
    return createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
        maxHeight: 450 + "px",
        overflowY: "auto",
    }], (elems_3 = toList(delay(() => {
        let elems_2, children_2, children, children_4;
        return (containers.length === 0) ? singleton(createElement("div", {
            className: "has-text-centered",
            children: "No containers were found for this shipment",
        })) : singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_2 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Container Number",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Seal Number",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Container Type",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Load Type",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_4 = toList(delay(() => map((container) => {
            let elems_1, elems;
            return createElement("tr", createObj(singleton_1((elems_1 = [createElement("td", createObj(ofArray([["className", "p-2"], (elems = [createElement("a", {
                className: join(" ", ["is-link", "is-underlined"]),
                href: RouterModule_encodeParts(ofArray(["shipments", shipmentId, "container-details", container.Id]), 1),
                children: container.Number,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", {
                className: "p-2",
                children: container.SealNumber,
            }), createElement("td", {
                className: "p-2",
                children: container.ContainerType,
            }), createElement("td", {
                className: "p-2",
                children: container.LoadType,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
        }, containers))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

