import { Record, toString, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { array_type, string_type, record_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { replace } from "../fable_modules/fable-library.4.10.0/String.js";
import { ofArray, map } from "../fable_modules/fable-library.4.10.0/List.js";
import { SelectableItem } from "../Models.fs.js";
import { PackingListMaterialReceipt, PackingListSearchRequest, PackingListLowBox, PackingList, MaterialReceiptSearchRequest, MaterialReceipt, PurchaseOrderSearchRequest, PurchaseOrder, GlobalAccountTotalCountResponse_get_Default, PackingListSearchRequest_$reflection, MaterialReceiptSearchRequest_$reflection, PurchaseOrderSearchRequest_$reflection } from "../../CView.DTOs/Inventory.fs.js";
import { defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { fromParts } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { DashboardQuery } from "../../CView.DTOs/DTOs.fs.js";
import { choose } from "../fable_modules/fable-library.4.10.0/Array.js";

export class InventorySearchType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PurchaseOrder", "MaterialReceipt", "PackingList"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Material Receipt" : ((this$.tag === 2) ? "Packing List" : "Purchase Order");
    }
}

export function InventorySearchType_$reflection() {
    return union_type("CView.UI.InventoryDomain.InventorySearchType", [], InventorySearchType, () => [[], [], []]);
}

/**
 * Convert the discriminated union type to a HTML element ID
 */
export function InventorySearchType__ToHtmlElementId(this$) {
    return replace(toString(this$), " ", "");
}

export function InventorySearchType_FromHtmlElementId_Z721C83C5(elementId) {
    switch (elementId) {
        case "PurchaseOrder":
            return new InventorySearchType(0, []);
        case "MaterialReceipt":
            return new InventorySearchType(1, []);
        case "PackingList":
            return new InventorySearchType(2, []);
        default:
            throw new Error("Inventory search type is not supported");
    }
}

export function InventorySearchType_AsDataSource() {
    return map((value) => (new SelectableItem(InventorySearchType__ToHtmlElementId(value), toString(value))), ofArray([new InventorySearchType(0, []), new InventorySearchType(1, []), new InventorySearchType(2, [])]));
}

export class InventoryQuery extends Record {
    constructor(SearchBy, PurchaseOrderQuery, MaterialReceiptQuery, PackingListQuery) {
        super();
        this.SearchBy = SearchBy;
        this.PurchaseOrderQuery = PurchaseOrderQuery;
        this.MaterialReceiptQuery = MaterialReceiptQuery;
        this.PackingListQuery = PackingListQuery;
    }
}

export function InventoryQuery_$reflection() {
    return record_type("CView.UI.InventoryDomain.InventoryQuery", [], InventoryQuery, () => [["SearchBy", InventorySearchType_$reflection()], ["PurchaseOrderQuery", PurchaseOrderSearchRequest_$reflection()], ["MaterialReceiptQuery", MaterialReceiptSearchRequest_$reflection()], ["PackingListQuery", PackingListSearchRequest_$reflection()]]);
}

export class PackingListFilterDataSources extends Record {
    constructor(Vendors, BoxNumbers, PurchaseOrderNumbers, MaterialReceiptNumbers) {
        super();
        this.Vendors = Vendors;
        this.BoxNumbers = BoxNumbers;
        this.PurchaseOrderNumbers = PurchaseOrderNumbers;
        this.MaterialReceiptNumbers = MaterialReceiptNumbers;
    }
}

export function PackingListFilterDataSources_$reflection() {
    return record_type("CView.UI.InventoryDomain.PackingListFilterDataSources", [], PackingListFilterDataSources, () => [["Vendors", array_type(string_type)], ["BoxNumbers", array_type(string_type)], ["PurchaseOrderNumbers", array_type(string_type)], ["MaterialReceiptNumbers", array_type(string_type)]]);
}

export const defaultPurchaseOrder = new PurchaseOrder("00000000-0000-0000-0000-000000000000", "", "", defaultOf(), new Array(0), new Array(0), new Array(0), 0, fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), 0, 0, GlobalAccountTotalCountResponse_get_Default());

export const defaultPurchaseOrderQuery = new PurchaseOrderSearchRequest(1, 15, String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), "Any", defaultOf(), defaultOf(), new Array(0));

export const defaultMaterialReceipt = (() => {
    const ReceivedDate = defaultOf();
    const MaterialReceiptDate = defaultOf();
    const PackedDate = defaultOf();
    const ScannedOutDate = defaultOf();
    return new MaterialReceipt("00000000-0000-0000-0000-000000000000", "", "", "", MaterialReceiptDate, ReceivedDate, defaultOf(), PackedDate, ScannedOutDate, new Array(0), new Array(0), GlobalAccountTotalCountResponse_get_Default());
})();

export const defaultMaterialReceiptQuery = new MaterialReceiptSearchRequest(1, 15, String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), "Any", defaultOf(), defaultOf(), new Array(0));

export const defaultPackingList = new PackingList("00000000-0000-0000-0000-000000000000", "", defaultOf(), "", "", defaultOf(), "", defaultOf(), "", defaultOf(), "", defaultOf(), "", defaultOf(), "", "", "", new Array(0), GlobalAccountTotalCountResponse_get_Default());

export const defaultLowBoxes = new PackingListLowBox("", new Array(0));

export const defaultPackingListQuery = new PackingListSearchRequest(1, 15, String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), String_defaultValue(), "Any", defaultOf(), defaultOf(), new Array(0));

export const defaultDashboardQuery = new DashboardQuery(new Array(0));

export const defaultInventoryQuery = new InventoryQuery(new InventorySearchType(0, []), defaultPurchaseOrderQuery, defaultMaterialReceiptQuery, defaultPackingListQuery);

export function filterPackingList(packingListLowBox, boxNumber, purchaseOrderNumber, materialReceiptsNumber, vendor) {
    return choose((box) => {
        const filteredReceipts = choose((materiaReceipt) => {
            const filteredItems = materiaReceipt.MaterialReceiptItems.filter((mrItem) => {
                if ((((boxNumber === "All") ? true : (box.BoxNumber === boxNumber)) && ((materialReceiptsNumber === "All") ? true : (materiaReceipt.MaterialReceiptNumber === materialReceiptsNumber))) && ((purchaseOrderNumber === "All") ? true : (mrItem.PurchaseOrderNumber === purchaseOrderNumber))) {
                    if (vendor === "All") {
                        return true;
                    }
                    else {
                        return mrItem.VendorName === vendor;
                    }
                }
                else {
                    return false;
                }
            });
            if (filteredItems.length > 0) {
                return new PackingListMaterialReceipt(materiaReceipt.Id, materiaReceipt.MaterialReceiptNumber, materiaReceipt.StatusName, materiaReceipt.WarehouseName, materiaReceipt.MaterialReceiptDate, materiaReceipt.ReceivedDate, materiaReceipt.ReleaseToPackDate, materiaReceipt.PackedDate, materiaReceipt.ScannedOutDate, filteredItems);
            }
            else {
                return void 0;
            }
        }, box.MaterialReceipts);
        if (filteredReceipts.length > 0) {
            return new PackingListLowBox(box.BoxNumber, filteredReceipts);
        }
        else {
            return void 0;
        }
    }, packingListLowBox);
}

export const defaultPackingListFilterDataSources = new PackingListFilterDataSources(new Array(0), new Array(0), new Array(0), new Array(0));

