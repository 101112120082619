import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, option_type, union_type, string_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { ShipmentDocument_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Alert_text, Alert_title, Alert_error } from "./SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { documentExport as documentExport_1 } from "../Api/ShipmentApi.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";

export class State extends Record {
    constructor(ExportDocument, SelectedDocument) {
        super();
        this.ExportDocument = ExportDocument;
        this.SelectedDocument = SelectedDocument;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.ShipmentDocument.ShipmentDocument.State", [], State, () => [["ExportDocument", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["SelectedDocument", option_type(ShipmentDocument_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExportDocument", "SetSelectedDocument"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.ShipmentDocument.ShipmentDocument.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", ShipmentDocument_$reflection()]]]);
}

export function init() {
    return [new State(new Deferred$1(0, []), void 0), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            return [new State(new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedDocument), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Document", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
        }
        else if (equals(state.ExportDocument, new Deferred$1(1, []))) {
            return [state, Cmd_none()];
        }
        else {
            const matchValue = state.SelectedDocument;
            if (matchValue != null) {
                const document$_1 = matchValue;
                return [new State(new Deferred$1(1, []), state.SelectedDocument), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, () => documentExport_1(document$_1.Id, document$_1.Name), void 0, (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
    }
    else {
        return [new State(state.ExportDocument, msg.fields[0]), singleton((dispatch) => {
            dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
        })];
    }
}

export function render(state, dispatch, documents) {
    let elems_8;
    return createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
        maxHeight: 450 + "px",
        overflowY: "auto",
    }], (elems_8 = toList(delay(() => {
        let elems_7, elems, children, elems_6;
        return (documents.length === 0) ? singleton_1(createElement("div", {
            className: "has-text-centered",
            children: "No documents were found for this shipment",
        })) : singleton_1(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_7 = [createElement("thead", createObj(singleton((elems = [(children = ofArray([createElement("th", {
            className: join(" ", ["p-2"]),
            scope: "col",
            children: "Document Type",
        }), createElement("th", {
            className: join(" ", ["p-2"]),
            scope: "col",
            children: "Document Name",
        }), createElement("th", {
            className: join(" ", ["p-2"]),
            scope: "col",
            children: "",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("tbody", createObj(singleton((elems_6 = toList(delay(() => map((document$) => {
            let elems_5, elems_4, elems_3, elems_2, elems_1;
            return createElement("tr", createObj(singleton((elems_5 = [createElement("td", {
                className: join(" ", ["p-2"]),
                children: document$.TypeName,
            }), createElement("td", {
                className: join(" ", ["p-2"]),
                children: document$.Name,
            }), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons"])], (elems_3 = [createElement("p", createObj(ofArray([["className", "control"], (elems_2 = [createElement("button", createObj(ofArray([["type", "button"], ["className", join(" ", ["button", "is-small"])], ["disabled", equals(state.ExportDocument, new Deferred$1(1, []))], ["onClick", (_arg) => {
                dispatch(new Msg(1, [document$]));
            }], (elems_1 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small", "pl-2"]),
                children: "file_download",
            }), createElement("span", {
                className: "pl-2",
                children: "Download",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]))));
        }, documents))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

