import { fromParts, op_Multiply, toNumber } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { value as value_163 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { formatDecimal } from "../Domain/Formatter.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";

export function render(shipment) {
    let elems_18, elems_3, elems, fmt, elems_1, fmt_1, elems_2, fmt_2, elems_7, elems_6, elems_4, elems_5, elems_17, elems_10, elems_9, elems_8, value_124, elems_13, elems_12, elems_11, value_140, elems_16, elems_15, elems_14, value_156;
    let emissionPercentage;
    const matchValue = shipment.EmissionsIntensity != null;
    if (matchValue) {
        const x = toNumber(op_Multiply(value_163(shipment.EmissionsIntensity), fromParts(1000, 0, 0, false, 1)));
        emissionPercentage = ((x > 90) ? 95 : x);
    }
    else {
        emissionPercentage = 0;
    }
    return createElement("div", createObj(ofArray([["className", join(" ", ["tab"])], ["id", "emissions"], ["style", {
        minHeight: 300 + "px",
        display: "block",
    }], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-centered", "is-multiline"])], (elems_3 = [createElement("div", createObj(ofArray([["className", "column"], (elems = [createElement("span", {
        className: "heading",
        children: "WTT",
    }), createElement("span", createObj(ofArray([["className", join(" ", ["title", "is-size-4"])], ((fmt = printf("%s kg CO2e"), fmt.cont((value_17) => ["children", value_17])))(formatDecimal(shipment.EmissionsWellToTank))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
        className: join(" ", ["column", "is-narrow", "is-flex", "is-align-items-center", "is-size-3", "has-text-grey-light"]),
        children: "+",
    }), createElement("div", createObj(ofArray([["className", "column"], (elems_1 = [createElement("span", {
        className: "heading",
        children: "TTW",
    }), createElement("span", createObj(ofArray([["className", join(" ", ["title", "is-size-4"])], ((fmt_1 = printf("%s kg CO2e"), fmt_1.cont((value_32) => ["children", value_32])))(formatDecimal(shipment.EmissionsTankToWheel))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", {
        className: join(" ", ["column", "is-narrow", "is-flex", "is-align-items-center", "is-size-3", "has-text-grey-light"]),
        children: "=",
    }), createElement("div", createObj(ofArray([["className", "column"], (elems_2 = [createElement("span", {
        className: "heading",
        children: "Total Emissions",
    }), createElement("span", createObj(ofArray([["className", join(" ", ["title", "is-size-4"])], ((fmt_2 = printf("%s kg CO2e"), fmt_2.cont((value_47) => ["children", value_47])))(formatDecimal(shipment.EmissionsTotal))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-centered", "is-multiline"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_4 = [createElement("span", {
        className: join(" ", ["is-size-6", "has-text-weight-bold"]),
        children: "Low",
    }), createElement("span", {
        className: join(" ", ["is-size-6", "has-text-weight-bold"]),
        children: "Medium",
    }), createElement("span", {
        className: join(" ", ["is-size-6", "has-text-weight-bold"]),
        children: "High",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", {
        style: {
            position: "relative",
            height: 50 + "px",
            backgroundImage: "linear-gradient(to right, green, yellow, orange, red)",
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", [])], ["style", {
        position: "relative",
        bottom: 12 + "px",
        left: emissionPercentage + "%",
        zIndex: 1,
    }], (elems_5 = [createElement("div", {
        style: {
            transform: ("translateX(" + (-50 + "%")) + ")",
            transform: ("rotate(" + 180) + "deg)",
            width: 0,
            height: 0,
            borderLeft: (((10 + "px ") + "solid") + " ") + "transparent",
            borderRight: (((10 + "px ") + "solid") + " ") + "transparent",
            borderTop: (((20 + "px ") + "solid") + " ") + "black",
        },
    }), createElement("div", {
        className: join(" ", ["is-size-6", "has-text-weight-bold"]),
        style: {
            marginLeft: -20,
        },
        children: "Intensity",
    }), createElement("div", {
        className: join(" ", ["is-size-6", "has-text-weight-bold"]),
        style: {
            marginLeft: -10,
        },
        children: formatDecimal(shipment.EmissionsIntensity),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("hr", {}), createElement("div", createObj(ofArray([["className", "columns"], (elems_17 = [createElement("div", createObj(ofArray([["className", "column"], (elems_10 = [createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_8 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "WTT",
    }), createElement("span", createObj(ofArray([["className", "list-item-description"], (value_124 = "Well-to-tank (WTT) emissions includes all greenhouse gas emissions from the production, transportation, transformation and distribution of the fuel used to power the vehicle.", ["children", value_124])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_13 = [createElement("div", createObj(ofArray([["className", "column"], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_11 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "TTW",
    }), createElement("span", createObj(ofArray([["className", "list-item-description"], (value_140 = "Tank-to-wheel (TTW) emissions includes all greenhouse gases emissions from combusting the fuel used to power the vehicle.", ["children", value_140])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_16 = [createElement("div", createObj(ofArray([["className", "column"], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_14 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "Total Emissions",
    }), createElement("span", createObj(ofArray([["className", "list-item-description"], (value_156 = "Total emissions, also known as Well-to-wheels (WTW), is used to assess the LCA of fuels, including all phases of its life cycle - from the extraction of raw materials to their use. There are two components to the well-to-wheels assessment: well-to-tank (WTT) and tank-to-wheels (TTW).", ["children", value_156])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])));
}

