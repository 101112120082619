import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";

export function render() {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-center"])], (elems = [createElement("div", {
        className: join(" ", []),
        children: "The page you requested was not found.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

