import { createElement } from "react";
import { comparePrimitives, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { empty, append, collect, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { DateTime_formatNullable } from "../Extensions.fs.js";
import { formatDecimalWithUnits } from "../Domain/Formatter.fs.js";
import { IconType, ModeOfTransportIcon_render } from "./ModeOfTransportIcon.fs.js";
import { value as value_68 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";

export function render(purchaseOrder) {
    let elems_10;
    return createElement("div", createObj(singleton_1((elems_10 = toList(delay(() => ((purchaseOrder.PackingLists.length === 0) ? singleton(createElement("div", {
        className: "has-text-centered",
        children: "No packing lists were found for this purchase order",
    })) : collect((packingList) => {
        let elems_9, elems_1, elems, elems_2, elems_3, elems_4, elems_5, elems_6, elems_8, elems_7, children;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [createElement("p", {
            children: ["Packing List #"],
        }), createElement("span", createObj(ofArray([["className", join(" ", ["is-size-6", "has-text-weight-bold", "has-text-link", "is-underlined"])], (elems = [createElement("a", {
            href: RouterModule_encodeParts(ofArray(["/packing-list-details", packingList.Id]), 1),
            children: packingList.PackingListNumber,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_2 = [createElement("p", {
            children: ["Ship Date"],
        }), createElement("span", {
            className: "has-text-weight-semibold",
            children: DateTime_formatNullable(packingList.ShipDate),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [createElement("p", {
            children: ["Imperial Volume"],
        }), createElement("span", {
            className: "has-text-weight-semibold",
            children: formatDecimalWithUnits(packingList.ImperialVolumeUnit, packingList.ImperialVolume),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("p", {
            children: ["Imperial Weight"],
        }), createElement("span", {
            className: "has-text-weight-semibold",
            children: formatDecimalWithUnits(packingList.ImperialWeightUnit, packingList.ImperialWeight),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_5 = [createElement("p", {
            children: ["Metric Volume"],
        }), createElement("span", {
            className: "has-text-weight-semibold",
            children: formatDecimalWithUnits(packingList.MetricVolumeUnit, packingList.MetricVolume),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_6 = [createElement("p", {
            children: ["Metric Weight"],
        }), createElement("span", {
            className: "has-text-weight-semibold",
            children: formatDecimalWithUnits(packingList.MetricWeightUnit, packingList.MetricWeight),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_7 = [ModeOfTransportIcon_render(new IconType(0, []), packingList.ModeOfTransportIcon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), (children = ofArray([createElement("a", {
            href: RouterModule_encodeParts(ofArray(["/shipment-details", (packingList.ShipmentId != null) ? value_68(packingList.ShipmentId) : ""]), 1),
            children: packingList.ShipmentNumber,
        }), createElement("div", {
            children: [packingList.ServiceTypeName],
        }), createElement("div", {
            children: [packingList.ServiceLevelName],
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => ((purchaseOrder.PackingLists.length > 1) ? singleton(createElement("hr", {})) : empty())));
    }, sortBy((x) => x.PackingListNumber, purchaseOrder.PackingLists, {
        Compare: comparePrimitives,
    }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))]))));
}

