import { printf, toText, format, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { value as value_1 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";

export function coalesceString(primary, secondary) {
    if (isNullOrWhiteSpace(primary)) {
        return secondary;
    }
    else {
        return primary;
    }
}

export function coalesceDateTimeOffset(primary, secondary) {
    if (primary != null) {
        return primary;
    }
    else {
        return secondary;
    }
}

export function coalesceDateTime(primary, secondary) {
    if (primary != null) {
        return primary;
    }
    else {
        return secondary;
    }
}

export function formatInt(value) {
    if (value != null) {
        return format("{0:#,0}", value);
    }
    else {
        return "";
    }
}

export function formatDecimal(value) {
    if (value != null) {
        return format("{0:#,##0.00}", value_1(value));
    }
    else {
        return "";
    }
}

export function formatIntWithUnits(unitOfMeasure, value) {
    const matchValue = value != null;
    const matchValue_1 = isNullOrWhiteSpace(unitOfMeasure);
    if (matchValue) {
        if (matchValue_1) {
            return formatInt(value);
        }
        else {
            const arg = formatInt(value);
            return toText(printf("%s %s"))(arg)(unitOfMeasure);
        }
    }
    else {
        return "";
    }
}

export function formatDecimalWithUnits(unitOfMeasure, value) {
    const matchValue = value != null;
    const matchValue_1 = isNullOrWhiteSpace(unitOfMeasure);
    if (matchValue) {
        if (matchValue_1) {
            return formatDecimal(value);
        }
        else {
            const arg = formatDecimal(value);
            return toText(printf("%s %s"))(arg)(unitOfMeasure);
        }
    }
    else {
        return "";
    }
}

/**
 * Use it to bind to an input since input type="date" expects a specific format
 */
export function toDateTimeInput(dateTime) {
    if (dateTime != null) {
        return toString(value_1(dateTime), "yyyy-MM-dd");
    }
    else {
        return String_defaultValue();
    }
}

/**
 * Use it to bind to an input since input type="date" expects a specific format
 */
export function toDateTimeOffsetInput(dateTime) {
    if (dateTime != null) {
        return toString(value_1(dateTime), "yyyy-MM-dd");
    }
    else {
        return String_defaultValue();
    }
}

export function formatShipmentStatusAsClass(statusName) {
    switch (statusName) {
        case "Booking":
            return "is-info";
        case "In Transit":
            return "is-success";
        case "Delivered":
            return "is-dark";
        default:
            return "";
    }
}

export function formatBookingStatusAsClass(statusName) {
    switch (statusName) {
        case "Confirmed":
            return "is-success";
        case "Pending Confirmation":
            return "is-info";
        case "Processing":
            return "is-dark";
        case "Rejected":
            return "is-danger";
        default:
            return "";
    }
}

export function formatModeOfTransportStatusAsClass(modeOfTransport) {
    switch (modeOfTransport) {
        case "Air":
            return "is-light is-success";
        case "Ocean":
            return "is-light is-info";
        case "Ground":
            return "is-light";
        default:
            return "";
    }
}

export function formatHasDelaysAsClass(hasDelays) {
    if (hasDelays) {
        return "is-danger";
    }
    else {
        return "";
    }
}

export function formatHasPotentialDelaysAsClass(hasPotentialDelays) {
    if (hasPotentialDelays) {
        return "is-warning";
    }
    else {
        return "";
    }
}

