import { LocalStorage_clearApplication } from "../Extensions.fs.js";
import { Result_MapError, Result_Bind, FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { SimpleJson_parseNative, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { ApiMessageResponse_$reflection } from "../../CView.DTOs/DTOs.fs.js";

export function tryParseAsApiMessage(response) {
    let input_1;
    switch (response.statusCode) {
        case 401: {
            LocalStorage_clearApplication();
            window.location.reload();
            return new FSharpResult$2(1, ["Failed authentication. Your session may have expired. Please try refreshing your browser."]);
        }
        case 403:
            return new FSharpResult$2(1, ["Request not allowed."]);
        case 404:
            return new FSharpResult$2(1, ["Failed to find the requested resource."]);
        default:
            if (isNullOrWhiteSpace(response.responseText)) {
                return new FSharpResult$2(1, ["Something really bad happened. Please contact the system administrator."]);
            }
            else {
                return Result_Bind((dto) => (new FSharpResult$2(1, [dto.Message])), Result_MapError((error) => {
                    console.error(some("API error"), error);
                    return "Something really bad happened. Please contact the system administrator.";
                }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                    try {
                        return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(ApiMessageResponse_$reflection()))]);
                    }
                    catch (ex) {
                        return new FSharpResult$2(1, [ex.message]);
                    }
                })())));
            }
    }
}

