import { Union } from "../CView.UI/fable_modules/fable-library.4.10.0/Types.js";
import { union_type } from "../CView.UI/fable_modules/fable-library.4.10.0/Reflection.js";

export class Location_Entity extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Shipment", "Booking"];
    }
}

export function Location_Entity_$reflection() {
    return union_type("CView.Constants.Location.Entity", [], Location_Entity, () => [[], []]);
}

export class Location_LocationType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Origin", "Destination", "PortOfLading", "PortOfDischarge"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "FinalDestination" : ((this$.tag === 2) ? "PortOfLading" : ((this$.tag === 3) ? "PortOfDischarge" : "PlaceOfReceipt"));
    }
}

export function Location_LocationType_$reflection() {
    return union_type("CView.Constants.Location.LocationType", [], Location_LocationType, () => [[], [], [], []]);
}

export class SearchType_Entity extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Shipment", "Booking"];
    }
}

export function SearchType_Entity_$reflection() {
    return union_type("CView.Constants.SearchType.Entity", [], SearchType_Entity, () => [[], []]);
}

export const UserTypeId_Client = "79687582-2d02-4856-bbcc-2826d5d7e4e2";

export const UserTypeId_CraneEmployee = "e3654e10-96f6-4b97-bc79-f7c9c254c9e7";

export const UserTypeId_ClientAdministrator = "7026b7d5-364b-4245-b193-018af2601e3c";

