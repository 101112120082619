import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { empty, map, append, collect, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { DateTimeOffset_formatNullable } from "../Extensions.fs.js";

export function render(materialReceipts) {
    let elems_13;
    return createElement("div", createObj(singleton_1((elems_13 = toList(delay(() => ((materialReceipts.length === 0) ? singleton(createElement("div", {
        className: "has-text-centered",
        children: "No material receipts were found for this purchase order",
    })) : collect((materialReceipt) => {
        let elems_12, elems_4, elems_3, elems_2, elems, elems_1, elems_11, elems_10, elems_9, elems_8;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["columns", "mt-3"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_4 = [createElement("div", createObj(singleton_1((elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-top", "is-flex-direction-column"])], (elems_2 = [createElement("span", createObj(ofArray([["className", join(" ", ["is-size-6", "has-text-weight-bold", "has-text-link", "is-underlined"])], (elems = [createElement("a", {
            href: RouterModule_encodeParts(ofArray(["/material-receipt-details", materialReceipt.Id]), 1),
            children: materialReceipt.MaterialReceiptNumber,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-top", "is-flex-direction-row"])], (elems_1 = [createElement("span", {
            className: join(" ", ["has-text-weight-semibold"]),
            children: "Warehouse",
        }), createElement("span", {
            className: join(" ", ["mx-2"]),
            children: materialReceipt.WarehouseName,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_11 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_10 = [createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("ul", createObj(ofArray([["className", join(" ", ["steps", "has-content-centered", "is-small"])], (elems_8 = toList(delay(() => map((milestone) => {
            let elems_7, elems_6, elems_5;
            return createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(!milestone.CompletedMilestone ? singleton("is-dashed") : empty(), delay(() => (milestone.LastMilestone ? singleton("is-active") : empty()))))))))], ["id", "527cabd8-c599-4630-96f0-018c2622de2e"], (elems_7 = [createElement("span", {
                className: "steps-marker",
            }), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_5 = [createElement("label", {
                className: "has-text-weight-semibold",
                children: milestone.Milestone,
            }), createElement("div", {
                children: [DateTimeOffset_formatNullable(milestone.Date)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
        }, materialReceipt.Milestones))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))), delay(() => ((materialReceipts.length > 1) ? singleton(createElement("hr", {})) : empty())));
    }, materialReceipts)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))]))));
}

