import { Lazy } from "./fable_modules/fable-library.4.10.0/Util.js";
import { defaultArg } from "./fable_modules/fable-library.4.10.0/Option.js";
import { where, collect, tryFind } from "./fable_modules/fable-library.4.10.0/Array.js";
import { isNullOrWhiteSpace, substring, split } from "./fable_modules/fable-library.4.10.0/String.js";
import { newGuid } from "./fable_modules/fable-library.4.10.0/Guid.js";
import { String_toQueryString } from "../CView.DTOs/PrimitiveTypes.fs.js";
import { ofArray } from "./fable_modules/fable-library.4.10.0/List.js";
import { LocalStorage_setIdToken, LocalStorage_getIdToken } from "./Extensions.fs.js";

const idTokenSingleton = new Lazy(() => defaultArg(tryFind((x_2) => (x_2 !== "#id_token"), collect((x_1) => split(x_1, ["="], void 0, 0), where((x) => (x.indexOf("#id_token") === 0), split(window.location.hash, ["&"], void 0, 0)))), ""));

export function getSignInUrl(userName) {
    const domain = (userName.indexOf("@") >= 0) ? substring(userName, userName.indexOf("@") + 1) : "";
    const guid = newGuid();
    const queryString = String_toQueryString(ofArray([["p", process.env.AAD_POLICY_NAME ? process.env.AAD_POLICY_NAME : ''], ["client_id", process.env.AAD_APPLICATION_ID ? process.env.AAD_APPLICATION_ID : ''], ["response_type", "id_token"], ["redirect_uri", window.location.origin], ["response_mode", "fragment"], ["scope", "openid offline_access"], ["nonce", guid], ["login_hint", userName], ["domain_hint", domain]]));
    return `https://${process.env.AAD_TENANT_NAME ? process.env.AAD_TENANT_NAME : ''}.b2clogin.com/${process.env.AAD_TENANT_NAME ? process.env.AAD_TENANT_NAME : ''}.onmicrosoft.com/oauth2/v2.0/authorize?${queryString}`;
}

export function getSignOutUrl(userName) {
    const queryString = String_toQueryString(ofArray([["p", process.env.AAD_POLICY_NAME ? process.env.AAD_POLICY_NAME : ''], ["login_hint", userName], ["post_logout_redirect_uri", window.location.origin]]));
    return `https://${process.env.AAD_TENANT_NAME ? process.env.AAD_TENANT_NAME : ''}.b2clogin.com/${process.env.AAD_TENANT_NAME ? process.env.AAD_TENANT_NAME : ''}.onmicrosoft.com/oauth2/v2.0/logout?${queryString}`;
}

export function getIdToken() {
    const storedIdToken = LocalStorage_getIdToken();
    const matchValue = isNullOrWhiteSpace(storedIdToken);
    if (matchValue) {
        const idToken = idTokenSingleton.Value;
        LocalStorage_setIdToken(idToken);
        return idToken;
    }
    else {
        return storedIdToken;
    }
}

export function isAuthenticated() {
    return !isNullOrWhiteSpace(getIdToken());
}

