import { init } from "./ApplicationInsights.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/List.js";

export const Interop_init = init;

export function ApplicationInsights_init(authenticatedUserId) {
    return singleton((_arg) => {
        Interop_init(authenticatedUserId);
    });
}

