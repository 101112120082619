import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { LocalStorage_setUserName, LocalStorage_getUserName } from "../Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { getSignInUrl } from "../Authentication.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";

export class State extends Record {
    constructor(UserName) {
        super();
        this.UserName = UserName;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Login.State", [], State, () => [["UserName", string_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUserName", "SignIn"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Login.Msg", [], Msg, () => [[["userName", string_type]], []]);
}

export function init() {
    return [new State(LocalStorage_getUserName()), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (isNullOrWhiteSpace(state.UserName)) {
            return [state, Cmd_none()];
        }
        else {
            LocalStorage_setUserName(state.UserName);
            const url = getSignInUrl(state.UserName);
            const objectArg = window.location;
            objectArg.replace(url);
            return [state, Cmd_none()];
        }
    }
    else {
        return [new State(msg.fields[0]), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_3, elems_2, elems_1, elems, value_21;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-mobile", "is-centered", "is-vcentered"])], ["style", {
        height: 80 + "vh",
    }], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems = [createElement("img", {
        className: join(" ", ["image", "p-6"]),
        alt: "C-View logo",
        src: "https://craneww-assets.azureedge.net/assets/crane-logo.svg",
    }), createElement("label", {
        children: "Email",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "p-2", "mt-1"])], ["type", "email"], ["autoComplete", "on"], ["placeholder", "name@company.com"], (value_21 = state.UserName, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_21)) {
            e.value = value_21;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }], ["onKeyDown", (e_1) => {
        if (e_1.key === "Enter") {
            dispatch(new Msg(1, []));
        }
    }]]))), createElement("button", {
        className: join(" ", ["button", "is-primary", "is-small", "my-4", "px-4", "py-2"]),
        type: "button",
        children: "Sign in",
        disabled: isNullOrWhiteSpace(state.UserName),
        onClick: (_arg) => {
            dispatch(new Msg(1, []));
        },
    }), createElement("a", {
        className: join(" ", ["mt-4", "has-text-centered", "is-link"]),
        href: "mailto:cview.support@craneww.com",
        children: "cview.support@craneww.com",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

