import { createElement } from "react";
import { comparePrimitives, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { value as value_128 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { formatDecimalWithUnits, formatInt } from "../Domain/Formatter.fs.js";
import { DateTimeOffset_formatNullable } from "../Extensions.fs.js";
import { sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";

export function render(purchaseOrderLineItems) {
    let elems_7;
    return createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
        maxHeight: 450 + "px",
        overflowY: "auto",
    }], (elems_7 = toList(delay(() => {
        let elems_6, children, elems, elems_5;
        return (purchaseOrderLineItems.length === 0) ? singleton(createElement("div", {
            className: "has-text-centered",
            children: "No purchase order line items were found for this shipment",
        })) : singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_6 = [(children = singleton_1(createElement("tr", createObj(ofArray([["className", ""], (elems = [createElement("th", {
            className: "p-2",
            children: "Box Number",
        }), createElement("th", {
            className: "p-2",
            children: "Packing List Number",
        }), createElement("th", {
            className: "p-2",
            children: "Purchase Order",
        }), createElement("th", {
            className: "p-2",
            children: "PO Item Number",
        }), createElement("th", {
            className: "p-2",
            children: "MR Number",
        }), createElement("th", {
            className: "p-2",
            children: "MR Item",
        }), createElement("th", {
            className: "p-2",
            children: "Vendor",
        }), createElement("th", {
            className: "p-2",
            children: "Part Number",
        }), createElement("th", {
            className: "p-2",
            children: "Description",
        }), createElement("th", {
            className: "p-2",
            children: "Quantity Received",
        }), createElement("th", {
            className: "p-2",
            children: "Promise Date",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("tbody", createObj(ofArray([["className", ""], (elems_5 = toList(delay(() => map((purchaseOrderLineItem) => {
            let elems_4, elems_1, elems_2, elems_3, _arg;
            return createElement("tr", createObj(ofArray([["className", ""], (elems_4 = [createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.BoxNumber,
            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_1 = [createElement("a", {
                className: join(" ", ["is-link", "is-underlined"]),
                href: RouterModule_encodeParts(ofArray(["/packing-list-details", purchaseOrderLineItem.PackingListId]), 1),
                children: purchaseOrderLineItem.PackingListNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_2 = [createElement("a", {
                className: join(" ", ["is-link", "is-underlined"]),
                href: RouterModule_encodeParts(ofArray(["/purchase-order-details", purchaseOrderLineItem.PurchaseOrderId]), 1),
                children: purchaseOrderLineItem.PurchaseOrderNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.PurchaseOrderLineItemNumber,
            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_3 = [createElement("a", {
                className: join(" ", ["is-link", "is-underlined"]),
                href: RouterModule_encodeParts(ofArray(["/material-receipt-details", (_arg = (purchaseOrderLineItem.MaterialReceiptId != null), _arg ? value_128(purchaseOrderLineItem.MaterialReceiptId) : "")]), 1),
                children: purchaseOrderLineItem.MaterialReceiptNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", {
                className: "p-2",
                children: formatInt(purchaseOrderLineItem.MaterialReceiptItem),
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.VendorName,
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.PartNumber,
            }), createElement("td", {
                className: "p-2",
                children: purchaseOrderLineItem.Description,
            }), createElement("td", {
                className: "p-2",
                children: formatDecimalWithUnits(purchaseOrderLineItem.QuantityReceivedUnit, purchaseOrderLineItem.QuantityReceived),
            }), createElement("td", {
                className: "p-2",
                children: DateTimeOffset_formatNullable(purchaseOrderLineItem.PromiseDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
        }, sortBy((purchaseOrderLineItem_1) => purchaseOrderLineItem_1.PurchaseOrderLineItemNumber, purchaseOrderLineItems, {
            Compare: comparePrimitives,
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

