import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_content, Http_send } from "../fable_modules/Fable.SimpleHttp.3.5.0/Http.fs.js";
import { HttpMethod, BodyContent } from "../fable_modules/Fable.SimpleHttp.3.5.0/Types.fs.js";
import { getIdToken } from "../Authentication.fs.js";
import { FSharpResult$2, Result_MapError } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { SimpleJson_parseNative, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { AnalyticsReportToken_$reflection, AnalyticsReport_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

export function getAnalyticsReports(accountIds) {
    return singleton.Delay(() => {
        let req_2, req_1;
        const payload = Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(accountIds);
        return singleton.Bind(Http_send(Http_content(new BodyContent(1, [payload]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Reports/Analytics/Reports`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(array_type(AnalyticsReport_$reflection())))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

export function getEmbedToken(accountIds) {
    return singleton.Delay(() => {
        let req_2, req_1;
        const payload = Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(accountIds);
        return singleton.Bind(Http_send(Http_content(new BodyContent(1, [payload]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Reports/Analytics/GetEmbedToken`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(AnalyticsReportToken_$reflection()))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

