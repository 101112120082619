import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { UserPreference, UserPreference_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, union_type, string_type, unit_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { saveUserPreferences } from "../Api/UserPreferencesApi.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { map as map_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(UserPreferences, SaveUserPreferencesDeferred) {
        super();
        this.UserPreferences = UserPreferences;
        this.SaveUserPreferencesDeferred = SaveUserPreferencesDeferred;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.UserPreferences.State", [], State, () => [["UserPreferences", array_type(UserPreference_$reflection())], ["SaveUserPreferencesDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UserPreferencesChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.UserPreferences.Intent", [], Intent, () => [[["Item", array_type(UserPreference_$reflection())]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUserPreferences", "SaveUserPreferencesAsync"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.UserPreferences.Msg", [], Msg, () => [[["Item", array_type(UserPreference_$reflection())]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(userPreferences) {
    return [new State(userPreferences, new Deferred$1(2, [new FSharpResult$2(0, [void 0])])), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.UserPreferences, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Update User Preferences", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("User Preferences", Toast_text("Your preferences have been updated"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(1, [])) : (new Intent(0, [state.UserPreferences]))];
        }
        else {
            return [new State(state.UserPreferences, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation) => {
                startImmediate(computation);
            }, saveUserPreferences, state.UserPreferences, (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg])]))), new Intent(1, [])];
        }
    }
    else {
        return [new State(msg.fields[0], state.SaveUserPreferencesDeferred), singleton((dispatch) => {
            dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
        }), new Intent(1, [])];
    }
}

export function renderUserPreference(state, userPreference, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems = [createElement("input", {
        className: "has-text-centered",
        id: userPreference.Name,
        name: userPreference.Name,
        type: "checkbox",
        checked: userPreference.Value,
        onChange: (ev) => {
            const _arg = ev.target.checked;
            dispatch(new Msg(0, [map((preference) => {
                if (preference.Name === userPreference.Name) {
                    return new UserPreference(userPreference.Id, preference.UserId, preference.CategoryId, userPreference.Name, !userPreference.Value);
                }
                else {
                    return preference;
                }
            }, state.UserPreferences)]));
        },
    }), createElement("label", {
        className: join(" ", ["has-text-weight-semibold", "ml-2", "is-clickable"]),
        for: userPreference.Name,
        children: userPreference.Name,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function render(state, dispatch) {
    let elems_2, elems, elems_1;
    const matchValue = state.SaveUserPreferencesDeferred;
    switch (matchValue.tag) {
        case 1:
            return render_1();
        case 2:
            if (matchValue.fields[0].tag === 0) {
                return createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", "box"], (elems = [createElement("h1", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
                    children: "Preferences",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_1 = toList(delay(() => map_1((userPreference) => renderUserPreference(state, userPreference, dispatch), state.UserPreferences))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
            }
            else {
                return defaultOf();
            }
        default:
            return defaultOf();
    }
}

