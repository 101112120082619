import { Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";

export class RelatedEntities extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PurchaseOrderLineItems", "MaterialReceipts", "PackingLists"];
    }
}

export function RelatedEntities_$reflection() {
    return union_type("CView.UI.PurchaseOrderDomain.RelatedEntities", [], RelatedEntities, () => [[], [], []]);
}

