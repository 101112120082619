import { FSharpRef, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { unit_type, lambda_type, record_type, array_type, list_type, union_type, string_type, bool_type, option_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { ClientUser, UserAccount, UserAccount_$reflection, GlobalAccountAnalyticsReport_$reflection, GlobalAccountModules_$reflection, GlobalAccount_$reflection, ClientUser_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Deferred_exists, Deferred_map, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { defaultClientUser, updatePermissions, SelectableTabs, isNewClientUser, SelectableTabs_$reflection } from "../Domain/User.fs.js";
import { UserTypeId_ClientAdministrator } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { append, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_question, Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { safeHash, createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getGlobalAccountAnalyticReports, getGlobalAccountModules, getGlobalAccount } from "../Api/AccountApi.fs.js";
import { append as append_2, collect, map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { map as map_1, defaultArgWith, defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { Cmd_ofEffect } from "../fable_modules/Feliz.Router.4.0.0/../Fable.Elmish.4.0.2/cmd.fs.js";
import { RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { findClientUser, deleteClientUser as deleteClientUser_1, updateClientUser as updateClientUser_1, createClientUser as createClientUser_1 } from "../Api/UserApi.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { createElement } from "react";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { collect as collect_1, map as map_2, empty, append as append_1, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { tryParse } from "../fable_modules/fable-library.4.10.0/Guid.js";
import { Array_distinct } from "../fable_modules/fable-library.4.10.0/Seq2.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(ClientUserId, IsNew, UserTypeId, GlobalAccountId, User, GlobalAccount, GlobalAccountModules, GlobalAccountAnalyticReports, SelectedAccounts, SelectedRegionalAccountId, UserName, UserEmail, ChangesMade, ActiveTab) {
        super();
        this.ClientUserId = ClientUserId;
        this.IsNew = IsNew;
        this.UserTypeId = UserTypeId;
        this.GlobalAccountId = GlobalAccountId;
        this.User = User;
        this.GlobalAccount = GlobalAccount;
        this.GlobalAccountModules = GlobalAccountModules;
        this.GlobalAccountAnalyticReports = GlobalAccountAnalyticReports;
        this.SelectedAccounts = SelectedAccounts;
        this.SelectedRegionalAccountId = SelectedRegionalAccountId;
        this.UserName = UserName;
        this.UserEmail = UserEmail;
        this.ChangesMade = ChangesMade;
        this.ActiveTab = ActiveTab;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.ClientUser.State", [], State, () => [["ClientUserId", option_type(class_type("System.Guid"))], ["IsNew", bool_type], ["UserTypeId", class_type("System.Guid")], ["GlobalAccountId", class_type("System.Guid")], ["User", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ClientUser_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ClientUser_$reflection()]], [["ErrorValue", string_type]]]))], ["GlobalAccount", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))], ["GlobalAccountModules", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccountModules_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccountModules_$reflection()]], [["ErrorValue", string_type]]]))], ["GlobalAccountAnalyticReports", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(GlobalAccountAnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(GlobalAccountAnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))], ["SelectedAccounts", array_type(UserAccount_$reflection())], ["SelectedRegionalAccountId", class_type("System.Guid")], ["UserName", string_type], ["UserEmail", string_type], ["ChangesMade", bool_type], ["ActiveTab", SelectableTabs_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetClientUser", "GetGlobalAccount", "GetGlobalAccountModules", "GetGlobalAccountAnalyticReports", "SetSelectedAccounts", "SetRegionalAccountFilter", "UpdateClientUserModel", "CreateClientUser", "UpdateClientUser", "DeleteClientUser", "ShowDeleteUserConfirmation", "SetActiveTab"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.ClientUser.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ClientUser_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ClientUser_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccountModules_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccountModules_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(GlobalAccountAnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(GlobalAccountAnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))]], [["Item1", bool_type], ["Item2", array_type(UserAccount_$reflection())]], [["Item", class_type("System.Guid")]], [["Item1", bool_type], ["Item2", lambda_type(ClientUser_$reflection(), ClientUser_$reflection())]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [], [["Item", SelectableTabs_$reflection()]]]);
}

function isInactiveUser(user) {
    if (!isNewClientUser(user)) {
        return !user.IsActive;
    }
    else {
        return false;
    }
}

function isReadOnlyUser(state, user) {
    if (isInactiveUser(user)) {
        return true;
    }
    else {
        return !(state.UserTypeId === UserTypeId_ClientAdministrator);
    }
}

function isUserAuthorizedToEdit(state) {
    return state.UserTypeId === UserTypeId_ClientAdministrator;
}

export function init(clientUserId, isNew, userTypeId, globalAccountId) {
    return [new State(clientUserId, isNew, userTypeId, globalAccountId, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Array(0), "00000000-0000-0000-0000-000000000000", "", "", false, new SelectableTabs(0, [])), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg(2, [new AsyncMsg$1(0, [])]));
    }), singleton((dispatch_2) => {
        dispatch_2(new Msg(3, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let matchValue_2, matchValue_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, new Deferred$1(2, [msg.fields[0].fields[0]]), state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Global Account", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
            }
            else if (equals(state.GlobalAccount, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, new Deferred$1(1, []), state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, getGlobalAccount, state.GlobalAccountId, (arg_2) => (new Msg(1, [new AsyncMsg$1(1, [arg_2])])))];
            }
        case 4:
            return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, msg.fields[1], state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), singleton((dispatch_1) => {
                dispatch_1(new Msg(6, [msg.fields[0], (user_2) => (new ClientUser(user_2.Id, user_2.UserTypeId, user_2.Email, user_2.DisplayName, user_2.IsActive, user_2.CanViewAirShipments, user_2.CanViewOceanShipments, user_2.CanViewGroundShipments, user_2.CanCreateBookings, user_2.CanViewBookings, user_2.CanExportSearchResults, user_2.CanViewInventory, user_2.CanViewAnalytics, user_2.CanViewRailShipments, user_2.CanViewCO2Emissions, user_2.CanViewBilling, map((account) => (new UserAccount(defaultArg(state.ClientUserId, "00000000-0000-0000-0000-000000000000"), account.AccountId, account.AccountName)), msg.fields[1]), user_2.AnalyticReportIds))]));
            })];
        case 6:
            return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, Deferred_map((input) => {
                const input_1 = input;
                if (input_1.tag === 1) {
                    return new FSharpResult$2(1, [input_1.fields[0]]);
                }
                else {
                    return new FSharpResult$2(0, [msg.fields[1](input_1.fields[0])]);
                }
            }, state.User), state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, msg.fields[0], state.ActiveTab), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                const command_5 = (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Global Account Modules", Alert_text(msg.fields[0].fields[0].fields[0]))) : singleton((dispatch_2) => {
                    dispatch_2(new Msg(0, [new AsyncMsg$1(0, [])]));
                });
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, (msg.fields[0].fields[0].tag === 0) ? ((matchValue_2 = state.User, (matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? (new Deferred$1(2, [new FSharpResult$2(0, [updatePermissions(matchValue_2.fields[0].fields[0], msg.fields[0].fields[0].fields[0])])])) : state.User) : state.User)) : state.User, state.GlobalAccount, new Deferred$1(2, [msg.fields[0].fields[0]]), state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), command_5];
            }
            else if (equals(state.GlobalAccountModules, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, new Deferred$1(1, []), state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), Cmd_OfAsyncWith_perform((computation_2) => {
                    startImmediate(computation_2);
                }, getGlobalAccountModules, state.GlobalAccountId, (arg_4) => (new Msg(2, [new AsyncMsg$1(1, [arg_4])])))];
            }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Global Account Analytic Reports", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
            }
            else if (equals(state.GlobalAccountAnalyticReports, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, new Deferred$1(1, []), state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), Cmd_OfAsyncWith_perform((computation_3) => {
                    startImmediate(computation_3);
                }, getGlobalAccountAnalyticReports, state.GlobalAccountId, (arg_6) => (new Msg(3, [new AsyncMsg$1(1, [arg_6])])))];
            }
        case 7:
            if (msg.fields[0].tag === 1) {
                let patternInput_1;
                if (msg.fields[0].fields[0].tag === 1) {
                    patternInput_1 = [Alert_error(Alert_title("Create User", Alert_text(msg.fields[0].fields[0].fields[0]))), state];
                }
                else {
                    const clientUserId = msg.fields[0].fields[0].fields[0];
                    patternInput_1 = [Cmd_batch(ofArray([Toast_success(Toast_title("Create User", Toast_text("The user has been created"))), Cmd_ofEffect((_arg) => {
                        const queryString_1 = singleton(["isNew", "true"]);
                        defaultArgWith(map_1((tupledArg) => {
                            RouterModule_nav(append(tupledArg[0], singleton(tupledArg[1] + RouterModule_encodeQueryString(queryString_1))), 1, 1);
                        }, RouterModule_trySeparateLast(ofArray(["user-details", clientUserId]))), () => {
                            RouterModule_nav(singleton(RouterModule_encodeQueryString(queryString_1)), 1, 1);
                        });
                    })])), new State(clientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, false, state.ActiveTab)];
                }
                return [patternInput_1[1], patternInput_1[0]];
            }
            else {
                const matchValue_3 = state.User;
                let matchResult, user_4;
                if (matchValue_3.tag === 2) {
                    if (matchValue_3.fields[0].tag === 0) {
                        matchResult = 0;
                        user_4 = matchValue_3.fields[0].fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return [state, Cmd_OfAsyncWith_perform((computation_4) => {
                            startImmediate(computation_4);
                        }, () => createClientUser_1(state.GlobalAccountId, user_4), void 0, (arg_8) => (new Msg(7, [new AsyncMsg$1(1, [arg_8])])))];
                    default:
                        return [state, Cmd_none()];
                }
            }
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, false, state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Update User", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_batch(singleton(Toast_success(Toast_title("Update User", Toast_text("The user has been updated")))))];
            }
            else {
                const matchValue_4 = state.User;
                let matchResult_1, user_5;
                if (matchValue_4.tag === 2) {
                    if (matchValue_4.fields[0].tag === 0) {
                        matchResult_1 = 0;
                        user_5 = matchValue_4.fields[0].fields[0];
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0: {
                        const user_6 = new ClientUser(user_5.Id, user_5.UserTypeId, state.UserEmail, state.UserName, user_5.IsActive, user_5.CanViewAirShipments, user_5.CanViewOceanShipments, user_5.CanViewGroundShipments, user_5.CanCreateBookings, user_5.CanViewBookings, user_5.CanExportSearchResults, user_5.CanViewInventory, user_5.CanViewAnalytics, user_5.CanViewRailShipments, user_5.CanViewCO2Emissions, user_5.CanViewBilling, user_5.Accounts, user_5.AnalyticReportIds);
                        return [state, Cmd_OfAsyncWith_perform((computation_5) => {
                            startImmediate(computation_5);
                        }, () => updateClientUser_1(state.GlobalAccountId, user_6), void 0, (arg_10) => (new Msg(8, [new AsyncMsg$1(1, [arg_10])])))];
                    }
                    default:
                        return [state, Cmd_none()];
                }
            }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [state, (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Inactive User", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_batch(ofArray([Toast_success(Toast_title("Inactive User", Toast_text("The user has been made inactive"))), Cmd_ofEffect((_arg_2) => {
                    RouterModule_nav(singleton("users"), 1, 1);
                })]))];
            }
            else {
                const matchValue_5 = state.User;
                let matchResult_2, user_7;
                if (matchValue_5.tag === 2) {
                    if (matchValue_5.fields[0].tag === 0) {
                        matchResult_2 = 0;
                        user_7 = matchValue_5.fields[0].fields[0];
                    }
                    else {
                        matchResult_2 = 1;
                    }
                }
                else {
                    matchResult_2 = 1;
                }
                switch (matchResult_2) {
                    case 0:
                        return [state, Cmd_OfAsyncWith_perform((computation_6) => {
                            startImmediate(computation_6);
                        }, () => deleteClientUser_1(state.GlobalAccountId, user_7.Id), void 0, (arg_12) => (new Msg(9, [new AsyncMsg$1(1, [arg_12])])))];
                    default:
                        return [state, Cmd_none()];
                }
            }
        case 10:
            return [state, Alert_question(new Msg(9, [new AsyncMsg$1(0, [])]), Alert_title("Inactive User", Alert_text("Are you sure you want to make the user inactive?")))];
        case 11:
            return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, msg.fields[0]), Cmd_none()];
        case 5:
            return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, state.User, state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, msg.fields[0], state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                let patternInput;
                if (msg.fields[0].fields[0].tag === 1) {
                    patternInput = [Alert_error(Alert_title("Get User", Alert_text(msg.fields[0].fields[0].fields[0]))), "", ""];
                }
                else {
                    const user = msg.fields[0].fields[0].fields[0];
                    patternInput = ((user.Id === "00000000-0000-0000-0000-000000000000") ? [Cmd_none(), "", ""] : [singleton((dispatch) => {
                        dispatch(new Msg(4, [false, user.Accounts]));
                    }), user.DisplayName, user.Email]);
                }
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, (msg.fields[0].fields[0].tag === 0) ? ((matchValue_1 = state.GlobalAccountModules, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new Deferred$1(2, [new FSharpResult$2(0, [updatePermissions(msg.fields[0].fields[0].fields[0], matchValue_1.fields[0].fields[0])])])) : state.User) : state.User)) : (new Deferred$1(2, [msg.fields[0].fields[0]])), state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, patternInput[1], patternInput[2], state.ChangesMade, state.ActiveTab), patternInput[0]];
            }
            else if (equals(state.User, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.ClientUserId, state.IsNew, state.UserTypeId, state.GlobalAccountId, new Deferred$1(1, []), state.GlobalAccount, state.GlobalAccountModules, state.GlobalAccountAnalyticReports, state.SelectedAccounts, state.SelectedRegionalAccountId, state.UserName, state.UserEmail, state.ChangesMade, state.ActiveTab), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, () => {
                    const matchValue = state.ClientUserId;
                    if (matchValue == null) {
                        return singleton_1.Return(new FSharpResult$2(0, [defaultClientUser]));
                    }
                    else {
                        return findClientUser(matchValue, state.IsNew);
                    }
                }, void 0, (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])])))];
            }
    }
}

export function render(state, dispatch) {
    let elems_62;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_62 = toList(delay(() => {
        let user, elems_5, elems_4;
        const matchValue_3 = state.User;
        return (matchValue_3.tag === 1) ? singleton_2(render_1()) : ((matchValue_3.tag === 2) ? ((matchValue_3.fields[0].tag === 0) ? append_1(singleton_2((user = matchValue_3.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "box"], (elems_5 = [createElement("h1", {
            className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
            children: isNewClientUser(user) ? "Users | New User" : "Users | User Details",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_4 = toList(delay(() => {
            let elems;
            return append_1(singleton_2(createElement("div", createObj(ofArray([["className", "column"], (elems = toList(delay(() => append_1(singleton_2(createElement("h1", {
                className: "has-text-link",
                children: isInactiveUser(user) ? (`${user.DisplayName} [${user.Email}] (inactive)`) : (isNewClientUser(user) ? "" : (`${user.DisplayName} [${user.Email}]`)),
            })), delay(() => (state.ChangesMade ? singleton_2(createElement("span", {
                className: join(" ", ["has-text-danger"]),
                children: "Attention: Changes have been made, please save.",
            })) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_3, elems_1, elems_2;
                return isUserAuthorizedToEdit(state) ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-justify-content-right"])], (elems_3 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], (elems_1 = [createElement("span", {
                    className: join(" ", ["material-icons", "is-small"]),
                    children: "delete",
                }), createElement("span", {
                    className: "is-sr-only",
                    children: "Delete User",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["onClick", (_arg) => {
                    dispatch(new Msg(10, []));
                }], ["disabled", isNewClientUser(user) ? true : isReadOnlyUser(state, user)]]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["disabled", !state.ChangesMade], ["type", "button"], (elems_2 = [createElement("span", {
                    className: join(" ", ["material-icons", "is-small"]),
                    children: "save",
                }), createElement("span", {
                    className: "is-sr-only",
                    children: "Save User",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["disabled", isReadOnlyUser(state, user)], ["onClick", (_arg_1) => {
                    if (isNewClientUser(user)) {
                        dispatch(new Msg(7, [new AsyncMsg$1(0, [])]));
                    }
                    else {
                        dispatch(new Msg(8, [new AsyncMsg$1(0, [])]));
                    }
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : empty();
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))), delay(() => {
            let user_1, elems_61;
            return singleton_2((user_1 = matchValue_3.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "box"], (elems_61 = toList(delay(() => {
                let elems_12, elems_8, elems_7, elems_6, value_71, elems_11, elems_10, elems_9, value_97;
                return append_1(singleton_2(createElement("div", createObj(ofArray([["className", "columns"], (elems_12 = [createElement("div", createObj(ofArray([["className", "column"], (elems_8 = [createElement("div", createObj(ofArray([["className", "field"], (elems_7 = [createElement("label", {
                    className: join(" ", ["label", "is-small"]),
                    children: "Name",
                }), createElement("div", createObj(ofArray([["className", "control"], (elems_6 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "text"], ["maxLength", 256], ["placeholder", "Name..."], (value_71 = (isNewClientUser(user_1) ? user_1.DisplayName : state.UserName), ["ref", (e) => {
                    if (!(e == null) && !equals(e.value, value_71)) {
                        e.value = value_71;
                    }
                }]), ["onChange", (ev) => {
                    const value_73 = ev.target.value;
                    dispatch(new Msg(6, [true, (user_2) => (new ClientUser(user_2.Id, user_2.UserTypeId, user_2.Email, value_73, user_2.IsActive, user_2.CanViewAirShipments, user_2.CanViewOceanShipments, user_2.CanViewGroundShipments, user_2.CanCreateBookings, user_2.CanViewBookings, user_2.CanExportSearchResults, user_2.CanViewInventory, user_2.CanViewAnalytics, user_2.CanViewRailShipments, user_2.CanViewCO2Emissions, user_2.CanViewBilling, user_2.Accounts, user_2.AnalyticReportIds))]));
                }], ["disabled", !isNewClientUser(user_1)]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_11 = [createElement("div", createObj(ofArray([["className", "field"], (elems_10 = [createElement("label", {
                    className: join(" ", ["label", "is-small"]),
                    children: "Email",
                }), createElement("div", createObj(ofArray([["className", "control"], (elems_9 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "email"], ["placeholder", "Email..."], ["maxLength", 256], (value_97 = (isNewClientUser(user_1) ? user_1.Email : state.UserEmail), ["ref", (e_1) => {
                    if (!(e_1 == null) && !equals(e_1.value, value_97)) {
                        e_1.value = value_97;
                    }
                }]), ["onChange", (ev_1) => {
                    const value_99 = ev_1.target.value;
                    dispatch(new Msg(6, [true, (user_3) => (new ClientUser(user_3.Id, user_3.UserTypeId, value_99, user_3.DisplayName, user_3.IsActive, user_3.CanViewAirShipments, user_3.CanViewOceanShipments, user_3.CanViewGroundShipments, user_3.CanCreateBookings, user_3.CanViewBookings, user_3.CanExportSearchResults, user_3.CanViewInventory, user_3.CanViewAnalytics, user_3.CanViewRailShipments, user_3.CanViewCO2Emissions, user_3.CanViewBilling, user_3.Accounts, user_3.AnalyticReportIds))]));
                }], ["disabled", !isNewClientUser(user_1)]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))), delay(() => {
                    let elems_60, elems_16, children, elems_13, elems_14, elems_15, elems_59, elems_28, elems_51, elems_58;
                    const isAnalyticsTabVisible = Deferred_exists((globalAccountModules) => {
                        if (globalAccountModules.tag === 0) {
                            return globalAccountModules.fields[0].CanViewAnalytics;
                        }
                        else {
                            return false;
                        }
                    }, state.GlobalAccountModules);
                    return singleton_2(createElement("div", createObj(ofArray([["className", "block"], (elems_60 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-boxed"])], ["id", "tabParent_top"], (elems_16 = [(children = ofArray([createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("has-text-weight-semibold"), delay(() => append_1(singleton_2("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton_2("is-active") : empty()))))))))], (elems_13 = [createElement("a", {
                        onClick: (_arg_2) => {
                            dispatch(new Msg(11, [new SelectableTabs(0, [])]));
                        },
                        children: "Accounts",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("has-text-weight-semibold"), delay(() => append_1(singleton_2("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(1, [])) ? singleton_2("is-active") : empty()))))))))], (elems_14 = [createElement("a", {
                        onClick: (_arg_3) => {
                            dispatch(new Msg(11, [new SelectableTabs(1, [])]));
                        },
                        children: "Permissions",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("has-text-weight-semibold"), delay(() => append_1(singleton_2("tablink"), delay(() => append_1(equals(state.ActiveTab, new SelectableTabs(2, [])) ? singleton_2("is-active") : empty(), delay(() => (!isAnalyticsTabVisible ? singleton_2("is-hidden") : empty()))))))))))], (elems_15 = [createElement("a", {
                        onClick: (_arg_4) => {
                            dispatch(new Msg(11, [new SelectableTabs(2, [])]));
                        },
                        children: "Analytics",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))]), createElement("ul", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", "tabcontainer"], ["id", "tabcontent"], (elems_59 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("tab"), delay(() => append_1(singleton_2("mx-4"), delay(() => (!equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton_2("is-hidden") : empty()))))))))], ["style", {
                        minHeight: 300 + "px",
                    }], (elems_28 = toList(delay(() => {
                        let elems_22, elems_21, elems_19, elems_18, elems_17, elems_20, fmt, array_2;
                        const matchValue = state.GlobalAccount;
                        let matchResult, globalAccount;
                        if (matchValue.tag === 2) {
                            if (matchValue.fields[0].tag === 0) {
                                matchResult = 0;
                                globalAccount = matchValue.fields[0].fields[0];
                            }
                            else {
                                matchResult = 1;
                            }
                        }
                        else {
                            matchResult = 1;
                        }
                        switch (matchResult) {
                            case 0: {
                                const filteredRegionalAccounts = globalAccount.Accounts.filter((regionalAccount) => {
                                    if (state.SelectedRegionalAccountId === "00000000-0000-0000-0000-000000000000") {
                                        return true;
                                    }
                                    else {
                                        return state.SelectedRegionalAccountId === regionalAccount.Id;
                                    }
                                });
                                return append_1(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-flex-direction-row", "is-justify-content-space-between", "mb-4"])], (elems_22 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-items-center"])], (elems_21 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-items-center"])], (elems_19 = [createElement("span", {
                                    className: "mr-2",
                                    children: globalAccount.Name,
                                }), createElement("div", createObj(ofArray([["className", "select"], (elems_18 = [createElement("select", createObj(ofArray([["className", join(" ", ["is-small", "is-fullwidth"])], (elems_17 = toList(delay(() => append_1(singleton_2(createElement("option", {
                                    selected: state.SelectedRegionalAccountId === "00000000-0000-0000-0000-000000000000",
                                    value: "00000000-0000-0000-0000-000000000000",
                                    children: "All",
                                })), delay(() => map_2((item) => createElement("option", {
                                    selected: item.Id === state.SelectedRegionalAccountId,
                                    value: item.Id,
                                    children: item.Name,
                                }), globalAccount.Accounts))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))]), ["onChange", (e_2) => {
                                    let outArg;
                                    const element = e_2.target;
                                    dispatch(new Msg(5, [((outArg = "00000000-0000-0000-0000-000000000000", [tryParse(element.value, new FSharpRef(() => outArg, (v) => {
                                        outArg = v;
                                    })), outArg]))[1]]));
                                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-items-center", "is-justify-content-end"])], (elems_20 = [createElement("div", createObj(ofArray([["className", join(" ", ["has-text-weight-semibold", "ml-6"])], ["children", "User has access to 7 of 8 total Accounts"], ((fmt = printf("User has access to %i of %i total Accounts"), fmt.cont((value_170) => ["children", value_170])))(state.SelectedAccounts.length)((array_2 = collect((x) => x.Accounts, globalAccount.Accounts), array_2.length))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))), delay(() => {
                                    let elems_27, elems_26, children_4, children_2, elems_23, array_13, elems_25;
                                    return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                                        overflowY: "auto",
                                    }], (elems_27 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-striped", "is-fullwidth"])], (elems_26 = [(children_4 = singleton((children_2 = ofArray([createElement("th", createObj(ofArray([["className", join(" ", ["is-size-7", "p-2", "has-text-centered"])], (elems_23 = [createElement("input", {
                                        style: {
                                            minWidth: 20 + "px",
                                        },
                                        type: "checkbox",
                                        className: join(" ", ["is-small", "p-2"]),
                                        onChange: (ev_2) => {
                                            dispatch(new Msg(4, [true, ev_2.target.checked ? Array_distinct(append_2(state.SelectedAccounts, map((account) => (new UserAccount(user_1.Id, account.Id, account.Name)), collect((x_1) => x_1.Accounts, filteredRegionalAccounts))), {
                                                Equals: equals,
                                                GetHashCode: safeHash,
                                            }) : state.SelectedAccounts.filter((selectedAccount) => {
                                                let array_9;
                                                return !((array_9 = collect((x_3) => x_3.Accounts, filteredRegionalAccounts), array_9.some((x_4) => (x_4.Id === selectedAccount.AccountId))));
                                            })]));
                                        },
                                        checked: (array_13 = collect((x_5) => x_5.Accounts, filteredRegionalAccounts), array_13.every((regionalAccount_1) => state.SelectedAccounts.some((account_1) => (regionalAccount_1.Id === account_1.AccountId)))),
                                        disabled: isReadOnlyUser(state, user_1),
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("th", {
                                        className: join(" ", ["is-size-7", "p-2"]),
                                        scope: "col",
                                        children: "Account Group",
                                    }), createElement("th", {
                                        className: join(" ", ["is-size-7", "p-2"]),
                                        scope: "col",
                                        children: "Account Name",
                                    }), createElement("th", {
                                        className: join(" ", ["is-size-7", "p-2"]),
                                        scope: "col",
                                        children: "Address",
                                    }), createElement("th", {
                                        className: join(" ", ["is-size-7", "p-2"]),
                                        scope: "col",
                                        children: "City",
                                    }), createElement("th", {
                                        className: join(" ", ["is-size-7", "p-2"]),
                                        scope: "col",
                                        children: "State/Province",
                                    }), createElement("th", {
                                        className: join(" ", ["is-size-7", "p-2"]),
                                        scope: "col",
                                        children: "Postal Code",
                                    })]), createElement("tr", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                                    }))), createElement("thead", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                                    })), createElement("tbody", createObj(singleton((elems_25 = toList(delay(() => collect_1((regionalAccount_2) => map_2((account_2) => {
                                        let elems_24;
                                        const children_6 = ofArray([createElement("td", createObj(ofArray([["className", join(" ", ["is-size-7", "p-2", "has-text-centered"])], (elems_24 = [createElement("input", {
                                            style: {
                                                minWidth: 20 + "px",
                                            },
                                            type: "checkbox",
                                            className: "is-small",
                                            checked: state.SelectedAccounts.some((selectedAccount_1) => (selectedAccount_1.AccountId === account_2.Id)),
                                            onChange: (ev_3) => {
                                                dispatch(new Msg(4, [true, ev_3.target.checked ? append_2([new UserAccount(user_1.Id, account_2.Id, account_2.Name)], state.SelectedAccounts) : state.SelectedAccounts.filter((selectedAccount_2) => (account_2.Id !== selectedAccount_2.AccountId))]));
                                            },
                                            disabled: isReadOnlyUser(state, user_1),
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("td", {
                                            className: join(" ", ["is-size-7", "p-2"]),
                                            children: regionalAccount_2.Name,
                                        }), createElement("td", {
                                            className: join(" ", ["is-size-7", "p-2"]),
                                            children: account_2.Name,
                                        }), createElement("td", {
                                            className: join(" ", ["is-size-7", "p-2"]),
                                            children: "",
                                        }), createElement("td", {
                                            className: join(" ", ["is-size-7", "p-2"]),
                                            children: "",
                                        }), createElement("td", {
                                            className: join(" ", ["is-size-7", "p-2"]),
                                            children: "",
                                        }), createElement("td", {
                                            className: join(" ", ["is-size-7", "p-2"]),
                                            children: "",
                                        })]);
                                        return createElement("tr", {
                                            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                                        });
                                    }, regionalAccount_2.Accounts), filteredRegionalAccounts))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))));
                                }));
                            }
                            default:
                                return singleton_2(defaultOf());
                        }
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("tab"), delay(() => append_1(singleton_2("mx-4"), delay(() => (!equals(state.ActiveTab, new SelectableTabs(1, [])) ? singleton_2("is-hidden") : empty()))))))))], ["id", "permissions"], ["style", {
                        minHeight: 300 + "px",
                        display: "block",
                    }], (elems_51 = toList(delay(() => {
                        let elems_50, elems_49, children_10, children_8, children_26, children_12, elems_29, elems_30, elems_31, children_14, elems_32, value_350, elems_38, elems_37, elems_33, elems_34, elems_35, elems_36, children_16, elems_39, elems_40, children_18, elems_41, elems_42, children_20, elems_43, value_509, elems_44, children_22, elems_45, value_538, elems_46, children_24, elems_47, elems_48;
                        const matchValue_1 = state.GlobalAccountModules;
                        let matchResult_1, globalAccountModules_1;
                        if (matchValue_1.tag === 2) {
                            if (matchValue_1.fields[0].tag === 0) {
                                matchResult_1 = 0;
                                globalAccountModules_1 = matchValue_1.fields[0].fields[0];
                            }
                            else {
                                matchResult_1 = 1;
                            }
                        }
                        else {
                            matchResult_1 = 1;
                        }
                        switch (matchResult_1) {
                            case 0:
                                return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], (elems_50 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-striped", "is-fullwidth"])], (elems_49 = [(children_10 = singleton((children_8 = ofArray([createElement("th", {
                                    className: "p-2",
                                    scope: "col",
                                    style: {
                                        width: 250 + "px",
                                    },
                                }), createElement("th", {
                                    className: "p-2",
                                    scope: "col",
                                    children: "Description",
                                }), createElement("th", {
                                    className: join(" ", ["p-2", "has-text-weight-semibold"]),
                                    scope: "col",
                                    children: "Can Access",
                                }), createElement("th", {
                                    className: join(" ", ["p-2", "has-text-weight-semibold"]),
                                    scope: "col",
                                    children: "Can Create",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                                }))), createElement("thead", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                                })), (children_26 = ofArray([(children_12 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_29 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "Bookings",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))), createElement("td", {
                                    className: "p-2",
                                    children: "Grants permission to view or create Bookings",
                                }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_30 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_4) => {
                                        const isChecked_2 = ev_4.target.checked;
                                        dispatch(new Msg(6, [true, (user_4) => (new ClientUser(user_4.Id, user_4.UserTypeId, user_4.Email, user_4.DisplayName, user_4.IsActive, user_4.CanViewAirShipments, user_4.CanViewOceanShipments, user_4.CanViewGroundShipments, user_4.CanCreateBookings, isChecked_2, user_4.CanExportSearchResults, user_4.CanViewInventory, user_4.CanViewAnalytics, user_4.CanViewRailShipments, user_4.CanViewCO2Emissions, user_4.CanViewBilling, user_4.Accounts, user_4.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewBookings,
                                    disabled: !globalAccountModules_1.CanViewBookings ? true : isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_31 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_5) => {
                                        const isChecked_3 = ev_5.target.checked;
                                        dispatch(new Msg(6, [true, (user_5) => (new ClientUser(user_5.Id, user_5.UserTypeId, user_5.Email, user_5.DisplayName, user_5.IsActive, user_5.CanViewAirShipments, user_5.CanViewOceanShipments, user_5.CanViewGroundShipments, isChecked_3, user_5.CanViewBookings, user_5.CanExportSearchResults, user_5.CanViewInventory, user_5.CanViewAnalytics, user_5.CanViewRailShipments, user_5.CanViewCO2Emissions, user_5.CanViewBilling, user_5.Accounts, user_5.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanCreateBookings,
                                    disabled: !globalAccountModules_1.CanViewBookings ? true : isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                                })), (children_14 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_32 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "Shipments",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (value_350 = "Grants permission to view Shipments by mode. This includes Documents. Please note users will not have access to shipment financials unless they are given Billing access. ", ["children", value_350])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_38 = [createElement("div", createObj(ofArray([["className", "checkboxes"], (elems_37 = [createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_33 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_6) => {
                                        const isChecked_4 = ev_6.target.checked;
                                        dispatch(new Msg(6, [true, (user_6) => (new ClientUser(user_6.Id, user_6.UserTypeId, user_6.Email, user_6.DisplayName, user_6.IsActive, isChecked_4, user_6.CanViewOceanShipments, user_6.CanViewGroundShipments, user_6.CanCreateBookings, user_6.CanViewBookings, user_6.CanExportSearchResults, user_6.CanViewInventory, user_6.CanViewAnalytics, user_6.CanViewRailShipments, user_6.CanViewCO2Emissions, user_6.CanViewBilling, user_6.Accounts, user_6.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewAirShipments,
                                    disabled: !globalAccountModules_1.CanViewShipments ? true : isReadOnlyUser(state, user_1),
                                }), createElement("span", {
                                    className: "ml-2",
                                    children: "Air",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_34 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_7) => {
                                        const isChecked_5 = ev_7.target.checked;
                                        dispatch(new Msg(6, [true, (user_7) => (new ClientUser(user_7.Id, user_7.UserTypeId, user_7.Email, user_7.DisplayName, user_7.IsActive, user_7.CanViewAirShipments, isChecked_5, user_7.CanViewGroundShipments, user_7.CanCreateBookings, user_7.CanViewBookings, user_7.CanExportSearchResults, user_7.CanViewInventory, user_7.CanViewAnalytics, user_7.CanViewRailShipments, user_7.CanViewCO2Emissions, user_7.CanViewBilling, user_7.Accounts, user_7.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewOceanShipments,
                                    disabled: !globalAccountModules_1.CanViewShipments ? true : isReadOnlyUser(state, user_1),
                                }), createElement("span", {
                                    className: "ml-2",
                                    children: "Ocean",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_35 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_8) => {
                                        const isChecked_6 = ev_8.target.checked;
                                        dispatch(new Msg(6, [true, (user_8) => (new ClientUser(user_8.Id, user_8.UserTypeId, user_8.Email, user_8.DisplayName, user_8.IsActive, user_8.CanViewAirShipments, user_8.CanViewOceanShipments, isChecked_6, user_8.CanCreateBookings, user_8.CanViewBookings, user_8.CanExportSearchResults, user_8.CanViewInventory, user_8.CanViewAnalytics, user_8.CanViewRailShipments, user_8.CanViewCO2Emissions, user_8.CanViewBilling, user_8.Accounts, user_8.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewGroundShipments,
                                    disabled: !globalAccountModules_1.CanViewShipments ? true : isReadOnlyUser(state, user_1),
                                }), createElement("span", {
                                    className: "ml-2",
                                    children: "Ground",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])]))), createElement("label", createObj(ofArray([["className", join(" ", ["checkbox", "is-flex", "is-align-items-center"])], (elems_36 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_9) => {
                                        const isChecked_7 = ev_9.target.checked;
                                        dispatch(new Msg(6, [true, (user_9) => (new ClientUser(user_9.Id, user_9.UserTypeId, user_9.Email, user_9.DisplayName, user_9.IsActive, user_9.CanViewAirShipments, user_9.CanViewOceanShipments, user_9.CanViewGroundShipments, user_9.CanCreateBookings, user_9.CanViewBookings, user_9.CanExportSearchResults, user_9.CanViewInventory, user_9.CanViewAnalytics, isChecked_7, user_9.CanViewCO2Emissions, user_9.CanViewBilling, user_9.Accounts, user_9.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewRailShipments,
                                    disabled: !globalAccountModules_1.CanViewShipments ? true : isReadOnlyUser(state, user_1),
                                }), createElement("span", {
                                    className: "ml-2",
                                    children: "Rail",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))), createElement("td", {
                                    className: "p-2",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                                })), (children_16 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_39 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "Inventory",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])]))), createElement("td", {
                                    className: "p-2",
                                    children: "Grants permission to view Purchase Order, Material Receipt, and Packling List information.",
                                }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_40 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_10) => {
                                        const isChecked_8 = ev_10.target.checked;
                                        dispatch(new Msg(6, [true, (user_10) => (new ClientUser(user_10.Id, user_10.UserTypeId, user_10.Email, user_10.DisplayName, user_10.IsActive, user_10.CanViewAirShipments, user_10.CanViewOceanShipments, user_10.CanViewGroundShipments, user_10.CanCreateBookings, user_10.CanViewBookings, user_10.CanExportSearchResults, isChecked_8, user_10.CanViewAnalytics, user_10.CanViewRailShipments, user_10.CanViewCO2Emissions, user_10.CanViewBilling, user_10.Accounts, user_10.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewInventory,
                                    disabled: !globalAccountModules_1.CanViewInventory ? true : isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])]))), createElement("td", {
                                    className: "p-2",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                                })), (children_18 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_41 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "Billing",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])]))), createElement("td", {
                                    className: "p-2",
                                    children: "Grants permission to view Billing and Invoice information",
                                }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_42 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_11) => {
                                        const isChecked_9 = ev_11.target.checked;
                                        dispatch(new Msg(6, [true, (user_11) => (new ClientUser(user_11.Id, user_11.UserTypeId, user_11.Email, user_11.DisplayName, user_11.IsActive, user_11.CanViewAirShipments, user_11.CanViewOceanShipments, user_11.CanViewGroundShipments, user_11.CanCreateBookings, user_11.CanViewBookings, user_11.CanExportSearchResults, user_11.CanViewInventory, user_11.CanViewAnalytics, user_11.CanViewRailShipments, user_11.CanViewCO2Emissions, isChecked_9, user_11.Accounts, user_11.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewBilling,
                                    disabled: !globalAccountModules_1.CanViewBilling ? true : isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])]))), createElement("td", {
                                    className: "p-2",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                                })), (children_20 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_43 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "Export",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (value_509 = "Grants permission to export data and download documents, but only for the specific modules (Booking, Shipment, Inventory, Billing) explicitly granted. ", ["children", value_509])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_44 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_12) => {
                                        const isChecked_10 = ev_12.target.checked;
                                        dispatch(new Msg(6, [true, (user_12) => (new ClientUser(user_12.Id, user_12.UserTypeId, user_12.Email, user_12.DisplayName, user_12.IsActive, user_12.CanViewAirShipments, user_12.CanViewOceanShipments, user_12.CanViewGroundShipments, user_12.CanCreateBookings, user_12.CanViewBookings, isChecked_10, user_12.CanViewInventory, user_12.CanViewAnalytics, user_12.CanViewRailShipments, user_12.CanViewCO2Emissions, user_12.CanViewBilling, user_12.Accounts, user_12.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanExportSearchResults ? true : isReadOnlyUser(state, user_1),
                                    disabled: isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])]))), createElement("td", {
                                    className: "p-2",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_20)),
                                })), (children_22 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_45 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "My Analytics",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (value_538 = "Grants access to the My Analytics module. Access to specific dashboards and reports using the \"Analytics\" user administration tab. ", ["children", value_538])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_46 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_13) => {
                                        const isChecked_11 = ev_13.target.checked;
                                        dispatch(new Msg(6, [true, (user_13) => (new ClientUser(user_13.Id, user_13.UserTypeId, user_13.Email, user_13.DisplayName, user_13.IsActive, user_13.CanViewAirShipments, user_13.CanViewOceanShipments, user_13.CanViewGroundShipments, user_13.CanCreateBookings, user_13.CanViewBookings, user_13.CanExportSearchResults, user_13.CanViewInventory, isChecked_11, user_13.CanViewRailShipments, user_13.CanViewCO2Emissions, user_13.CanViewBilling, user_13.Accounts, user_13.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewAnalytics,
                                    disabled: !globalAccountModules_1.CanViewAnalytics ? true : isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])]))), createElement("td", {
                                    className: "p-2",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_22)),
                                })), (children_24 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_47 = [createElement("span", {
                                    className: "has-text-weight-semibold",
                                    children: "CO2 emissions",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])]))), createElement("td", {
                                    className: "p-2",
                                    children: "Grants access to the CO2 emissions module. ",
                                }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_48 = [createElement("input", {
                                    style: {
                                        minWidth: 20 + "px",
                                    },
                                    type: "checkbox",
                                    className: "is-small",
                                    onChange: (ev_14) => {
                                        const isChecked_12 = ev_14.target.checked;
                                        dispatch(new Msg(6, [true, (user_14) => (new ClientUser(user_14.Id, user_14.UserTypeId, user_14.Email, user_14.DisplayName, user_14.IsActive, user_14.CanViewAirShipments, user_14.CanViewOceanShipments, user_14.CanViewGroundShipments, user_14.CanCreateBookings, user_14.CanViewBookings, user_14.CanExportSearchResults, user_14.CanViewInventory, user_14.CanViewAnalytics, user_14.CanViewRailShipments, isChecked_12, user_14.CanViewBilling, user_14.Accounts, user_14.AnalyticReportIds))]));
                                    },
                                    checked: user_1.CanViewCO2Emissions,
                                    disabled: !globalAccountModules_1.CanViewCO2Emissions ? true : isReadOnlyUser(state, user_1),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])]))), createElement("td", {
                                    className: "p-2",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_24)),
                                }))]), createElement("tbody", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_26)),
                                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])]))));
                            default:
                                return singleton_2(defaultOf());
                        }
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_51))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("tab"), delay(() => append_1(singleton_2("mx-4"), delay(() => (!equals(state.ActiveTab, new SelectableTabs(2, [])) ? singleton_2("is-hidden") : empty()))))))))], ["id", "analytics"], ["style", {
                        minHeight: 300 + "px",
                    }], (elems_58 = toList(delay(() => {
                        let elems_57, elems_56, children_30, children_28, elems_55;
                        const matchValue_2 = state.GlobalAccountAnalyticReports;
                        let matchResult_2, globalAccountAnalyticReports;
                        if (matchValue_2.tag === 2) {
                            if (matchValue_2.fields[0].tag === 0) {
                                matchResult_2 = 0;
                                globalAccountAnalyticReports = matchValue_2.fields[0].fields[0];
                            }
                            else {
                                matchResult_2 = 1;
                            }
                        }
                        else {
                            matchResult_2 = 1;
                        }
                        switch (matchResult_2) {
                            case 0:
                                return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], (elems_57 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-striped", "is-fullwidth"])], (elems_56 = [(children_30 = singleton((children_28 = ofArray([createElement("th", {
                                    className: join(" ", ["p-2", "has-text-weight-semibold"]),
                                    scope: "col",
                                    children: "View",
                                }), createElement("th", {
                                    className: join(" ", ["p-2", "has-text-weight-semibold"]),
                                    scope: "col",
                                    children: "Title",
                                }), createElement("th", {
                                    className: join(" ", ["p-2", "has-text-weight-semibold"]),
                                    scope: "col",
                                    children: "Description",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_28)),
                                }))), createElement("thead", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_30)),
                                })), createElement("tbody", createObj(singleton((elems_55 = toList(delay(() => map_2((analyticReport) => {
                                    let elems_52, elems_53, elems_54;
                                    const children_32 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_52 = [createElement("input", {
                                        style: {
                                            minWidth: 20 + "px",
                                        },
                                        type: "checkbox",
                                        className: "is-small",
                                        onChange: (ev_15) => {
                                            const isChecked_13 = ev_15.target.checked;
                                            dispatch(new Msg(6, [true, (user_15) => (new ClientUser(user_15.Id, user_15.UserTypeId, user_15.Email, user_15.DisplayName, user_15.IsActive, user_15.CanViewAirShipments, user_15.CanViewOceanShipments, user_15.CanViewGroundShipments, user_15.CanCreateBookings, user_15.CanViewBookings, user_15.CanExportSearchResults, user_15.CanViewInventory, user_15.CanViewAnalytics, user_15.CanViewRailShipments, user_15.CanViewCO2Emissions, user_15.CanViewBilling, user_15.Accounts, isChecked_13 ? append_2([analyticReport.AnalyticsReportId], user_15.AnalyticReportIds) : user_15.AnalyticReportIds.filter((reportId) => (reportId !== analyticReport.AnalyticsReportId))))]));
                                        },
                                        checked: user_1.AnalyticReportIds.some((reportId_1) => (reportId_1 === analyticReport.AnalyticsReportId)),
                                        disabled: isReadOnlyUser(state, user_1),
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_52))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_53 = [createElement("span", {
                                        children: [analyticReport.Name],
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_53))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_54 = [createElement("span", {
                                        children: [""],
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_54))])])))]);
                                    return createElement("tr", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_32)),
                                    });
                                }, globalAccountAnalyticReports))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_55))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_56))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_57))])]))));
                            default:
                                return singleton_2(defaultOf());
                        }
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_58))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_59))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_60))])]))));
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_61))])])))));
        })) : singleton_2(defaultOf())) : singleton_2(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_62))])])));
}

