import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { empty, singleton, append, map, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { printf, toText, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { IconType, ModeOfTransportIcon_render } from "./ModeOfTransportIcon.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { DateTimeOffset_formatNullable, System_String__String_get_NonEmpty, DateTime_formatNullable } from "../Extensions.fs.js";
import { formatDecimal, formatDecimalWithUnits, formatIntWithUnits } from "../Domain/Formatter.fs.js";

export function render(isPinHidden, canViewCO2Emissions, shipments) {
    let elems_33;
    if (shipments.length === 0) {
        return defaultOf();
    }
    else {
        return createElement("div", createObj(ofArray([["className", "box"], (elems_33 = toList(delay(() => map((shipment) => {
            const children_2 = toList(delay(() => {
                let elems_2, elems, elems_1;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-justify-content-space-between", "is-flex-wrap-wrap", "my-4"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-center"])], (elems = [ModeOfTransportIcon_render(new IconType(0, []), shipment.ModeOfTransportIcon), createElement("h1", {
                    className: join(" ", ["is-size-6", "has-text-weight-semibold"]),
                    children: shipment.LatestCompletedMilestoneName,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-center"])], (elems_1 = toList(delay(() => append(shipment.HasDelays ? singleton(createElement("span", {
                    className: join(" ", ["tag", "is-danger", "mr-1"]),
                    children: "Delayed",
                })) : empty(), delay(() => append(shipment.HasPotentialDelays ? singleton(createElement("span", {
                    className: join(" ", ["tag", "is-warning", "ml-1"]),
                    children: "Potential Delay",
                })) : empty(), delay(() => (!isPinHidden ? singleton(createElement("i", {
                    className: join(" ", toList(delay(() => append(singleton("material-icons"), delay(() => append(singleton("mot-icon"), delay(() => append(singleton("m1-3"), delay(() => (shipment.IsPinned ? singleton("has-text-link-dark") : singleton("has-text-grey-light"))))))))))),
                    children: "push_pin",
                })) : empty()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_32, elems_7, elems_6, elems_31, elems_30, elems_29, elems_28;
                    return append(singleton(createElement("div", createObj(ofArray([["className", "columns"], ["style", {
                        overflowX: "auto",
                    }], (elems_32 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_6 = toList(delay(() => append(singleton(createElement("a", {
                        href: RouterModule_encodeParts(ofArray(["/shipment-details", shipment.Id]), 1),
                        children: shipment.ShipmentNumber,
                    })), delay(() => append(singleton(createElement("div", {
                        children: [shipment.ServiceType],
                    })), delay(() => append(singleton(createElement("div", {
                        children: [shipment.ServiceLevel],
                    })), delay(() => {
                        let children;
                        return append(singleton((children = ofArray([createElement("span", {
                            className: join(" ", ["has-text-weight-semibold", "mr-2"]),
                            children: "Due Date",
                        }), createElement("span", {
                            children: [DateTime_formatNullable(shipment.DueDate)],
                        })]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))), delay(() => {
                            let elems_3;
                            return append(singleton(createElement("div", createObj(ofArray([["className", "is-flex"], (elems_3 = toList(delay(() => append(singleton(createElement("span", {
                                className: "has-text-weight-semibold",
                                children: formatIntWithUnits(shipment.TotalPiecesUnit, shipment.TotalPieces),
                            })), delay(() => ((shipment.TotalChargeableWeight != null) ? append(singleton(createElement("span", {
                                className: join(" ", ["has-text-weight-semibold", "mx-2"]),
                                children: " | ",
                            })), delay(() => singleton(createElement("span", {
                                className: "has-text-weight-semibold",
                                children: formatDecimalWithUnits(shipment.TotalChargeableWeightUnit, shipment.TotalChargeableWeight),
                            })))) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                                let elems_5, elems_4, arg;
                                return canViewCO2Emissions ? ((shipment.TotalEmissions != null) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "has-text-weight-semibold", "has-text-success", "mt-1"])], (elems_5 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_4 = [createElement("span", {
                                    className: "material-icons",
                                    children: "eco",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                                    children: [(arg = formatDecimal(shipment.TotalEmissions), toText(printf("Total Emissions: %s kg CO2e"))(arg))],
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))) : empty()) : empty();
                            }));
                        }));
                    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_31 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_30 = [createElement("div", createObj(ofArray([["className", "column"], (elems_29 = [createElement("ul", createObj(ofArray([["className", join(" ", ["steps", "has-content-centered", "is-small", "mb-2"])], (elems_28 = toList(delay(() => append(!shipment.HasVisibleSteps ? singleton(createElement("li", {
                        className: join(" ", ["steps-segment", "is-active", "is-hidden"]),
                    })) : empty(), delay(() => {
                        let elems_12, elems_9, elems_8, elems_11, elems_10;
                        return append(shipment.PlaceOfReceipt.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(singleton(shipment.PlaceOfReceipt.StepClass), delay(() => (!(shipment.PortOfLading.DelayReason == null) ? singleton("is-danger") : empty()))))))))], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.PlaceOfReceipt.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_9 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_8 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.PlaceOfReceipt.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_10 = toList(delay(() => append(singleton(createElement("label", {
                            className: "has-text-weight-semibold",
                            children: "Place Of Receipt",
                        })), delay(() => append(singleton(createElement("div", {
                            children: [shipment.PlaceOfReceipt.Location],
                        })), delay(() => {
                            let arg_1;
                            return append(singleton(createElement("div", {
                                children: (shipment.PlaceOfReceipt.Date != null) ? (shipment.PlaceOfReceipt.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.PlaceOfReceipt.Date) : ((arg_1 = DateTimeOffset_formatNullable(shipment.PlaceOfReceipt.Date), toText(printf("%s %s"))(arg_1)("(ESTIMATED)")))) : "",
                            })), delay(() => (System_String__String_get_NonEmpty(shipment.PlaceOfReceipt.DelayReason) ? singleton(createElement("div", {
                                className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                children: shipment.PlaceOfReceipt.DelayReason,
                            })) : empty())));
                        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))) : empty(), delay(() => {
                            let elems_17, elems_14, elems_13, elems_16, elems_15;
                            return append(shipment.PortOfLading.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(singleton(shipment.PortOfLading.StepClass), delay(() => (!(shipment.PortOfDischarge.DelayReason == null) ? singleton("is-danger") : empty()))))))))], (elems_17 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfLading.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_14 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_13 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.PortOfLading.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_15 = toList(delay(() => append(singleton(createElement("label", {
                                className: "has-text-weight-semibold",
                                children: "Port Of Lading",
                            })), delay(() => append(singleton(createElement("div", {
                                children: [shipment.PortOfLading.Location],
                            })), delay(() => {
                                let arg_3;
                                return append(singleton(createElement("div", {
                                    children: (shipment.PortOfLading.Date != null) ? (shipment.PortOfLading.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.PortOfLading.Date) : ((arg_3 = DateTimeOffset_formatNullable(shipment.PortOfLading.Date), toText(printf("%s %s"))(arg_3)("(ESTIMATED)")))) : "",
                                })), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfLading.DelayReason) ? singleton(createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                    children: shipment.PortOfLading.DelayReason,
                                })) : empty())));
                            })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))) : empty(), delay(() => {
                                let elems_22, elems_19, elems_18, elems_21, elems_20;
                                return append(shipment.PortOfDischarge.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(singleton(shipment.PortOfDischarge.StepClass), delay(() => (!(shipment.FinalDestination.DelayReason == null) ? singleton("is-danger") : empty()))))))))], (elems_22 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfDischarge.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_19 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_18 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.PortOfDischarge.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_21 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_20 = toList(delay(() => append(singleton(createElement("label", {
                                    className: "has-text-weight-semibold",
                                    children: "Port Of Discharge",
                                })), delay(() => append(singleton(createElement("div", {
                                    children: [shipment.PortOfDischarge.Location],
                                })), delay(() => {
                                    let arg_5;
                                    return append(singleton(createElement("div", {
                                        children: (shipment.PortOfDischarge.Date != null) ? (shipment.PortOfDischarge.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.PortOfDischarge.Date) : ((arg_5 = DateTimeOffset_formatNullable(shipment.PortOfDischarge.Date), toText(printf("%s %s"))(arg_5)("(ESTIMATED)")))) : "",
                                    })), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfDischarge.DelayReason) ? singleton(createElement("div", {
                                        className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                        children: shipment.PortOfDischarge.DelayReason,
                                    })) : empty())));
                                })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))) : empty(), delay(() => {
                                    let elems_27, elems_24, elems_23, elems_26, elems_25;
                                    return shipment.FinalDestination.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", ["steps-segment", shipment.FinalDestination.StepClass])], (elems_27 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.FinalDestination.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_24 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_23 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.FinalDestination.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_26 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_25 = toList(delay(() => append(singleton(createElement("label", {
                                        className: "has-text-weight-semibold",
                                        children: "Final Destination",
                                    })), delay(() => append(singleton(createElement("div", {
                                        children: [shipment.FinalDestination.Location],
                                    })), delay(() => {
                                        let arg_7;
                                        return append(singleton(createElement("div", {
                                            children: (shipment.FinalDestination.Date != null) ? (shipment.FinalDestination.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.FinalDestination.Date) : ((arg_7 = DateTimeOffset_formatNullable(shipment.FinalDestination.Date), toText(printf("%s %s"))(arg_7)("(ESTIMATED)")))) : "",
                                        })), delay(() => (System_String__String_get_NonEmpty(shipment.FinalDestination.DelayReason) ? singleton(createElement("div", {
                                            className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                            children: shipment.FinalDestination.DelayReason,
                                        })) : empty())));
                                    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))) : empty();
                                }));
                            }));
                        }));
                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))), delay(() => ((shipments.length > 1) ? singleton(createElement("hr", {})) : empty())));
                }));
            }));
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            });
        }, shipments))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
    }
}

