import { hideReports, render } from "./PowerBI.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/List.js";

export const Interop_render = render;

export const Interop_hideReports = hideReports;

export function PowerBI_render(reportElementId, reportId, isGlobal, accessToken, accountExternalIds, globalAccountExternalId, pageName) {
    return singleton((_arg) => {
        Interop_render(reportElementId, reportId, isGlobal, accessToken, accountExternalIds, globalAccountExternalId, pageName);
    });
}

export function PowerBI_hideReports() {
    return singleton((_arg) => {
        Interop_hideReports();
    });
}

