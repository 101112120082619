import { materialReceiptCountByStatusChart, purhaseOrderCountByStatusChart, materialReceiptWarehouseCountByNameChart, spendingOverTimeChart, lanePairAltChart, lanePairChart, inTransit } from "./Chart.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/List.js";

export const Interop_inTransit = inTransit;

export const Interop_lanePairChart = lanePairChart;

export const Interop_lanePairAltChart = lanePairAltChart;

export const Interop_spendingOverTimeChart = spendingOverTimeChart;

export const Interop_materialReceiptWarehouseCountByNameChart = materialReceiptWarehouseCountByNameChart;

export const Interop_purhaseOrderCountByStatusChart = purhaseOrderCountByStatusChart;

export const Interop_materialReceiptCountByStatusChart = materialReceiptCountByStatusChart;

export function Chart_inTransit(canvasId, datasetLabel, model) {
    return singleton((_arg) => {
        Interop_inTransit(canvasId, datasetLabel, model);
    });
}

export function Chart_lanePairChart(canvasId, models) {
    return singleton((_arg) => {
        Interop_lanePairChart(canvasId, models);
    });
}

export function Chart_lanePairAltChart(canvasId, models) {
    return singleton((_arg) => {
        Interop_lanePairAltChart(canvasId, models);
    });
}

export function Chart_spendingOverTimeChart(canvasId, models) {
    return singleton((_arg) => {
        Interop_spendingOverTimeChart(canvasId, models);
    });
}

export function Chart_materialReceiptWarehouseCountByNameChart(canvasId, models) {
    return singleton((_arg) => {
        Interop_materialReceiptWarehouseCountByNameChart(canvasId, models);
    });
}

export function Chart_purhaseOrderCountByStatusChart(canvasId, models) {
    return singleton((_arg) => {
        Interop_purhaseOrderCountByStatusChart(canvasId, models);
    });
}

export function Chart_materialReceiptCountByStatusChart(canvasId, models) {
    return singleton((_arg) => {
        Interop_materialReceiptCountByStatusChart(canvasId, models);
    });
}

